import React, { useEffect, useState } from 'react'
const TownCity = (props) => {

    const [titles, setTitles] = useState('');
    const [size, setSize] = useState(12);
    useEffect(() => {
        
        setTitles(props.loginTitle);
        setSize(props.size);
    }, [props.loginTitle, props.size]);

   

    return (
        <>
            <div className={"col-md-"+size}>
                <div className="form-floating">
                    <input type="text" disabled={props.inputState} className="form-control" id="townCity" placeholder="Your Town / City" value={props.townCity}
                        onChange={(event) => {
                            props.setTownCity(event.target.value);
                        }}
                    />
                    <label for="townCity">{titles}</label>
                </div>
            </div>
        </>
    )
}

export default TownCity
