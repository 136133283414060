import React from "react";
import Dashboard from "./childComponents/pageLayouts/Dashboard";
import DashboardNavbar from '../../layouts/frontend/DashboardNavbar';
import ItemsPurchaseDetail from '../../components/frontend/childComponents/pageLayouts/ItemsPurchaseDetail';
const Dashboards = (props) => {
    let tagName;

    switch (props.names) {
        case "ItemsPurchaseDetails":
            tagName = <ItemsPurchaseDetail catigoryChild={props.catigory} />;
            break;
        case "OrderDetails":
            tagName = <ItemsPurchaseDetail catigoryChild={props.catigory} />;
            break;
        case "Dashboard":
            tagName = <Dashboard catigoryChild={props.catigory} />;
            break;

        default:
            tagName = <Dashboard catigoryChild={props.catigory} />;


    }

    return (
        <>
            {/* Dashboards  */}
            <div className="container bg-light overflow-hidden my-5 px-lg-0">
                <div className="container px-lg-0">

                    <div className="row g-0 mx-lg-0">
                        {tagName}
                        <DashboardNavbar />
                    </div>
                </div>
            </div>
            {/* Dashboards  */}
        </>

    )
}

export default Dashboards
