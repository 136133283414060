import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import Loading from "../appComponents/Loading";
const Product = () => {

    const [allProducts, setAllProducts] = useState([]); //store defualt search records
    const [allCatigories, setAllCatigories] = useState([]); //store defualt search records
    const [imageBends, setImageBends] = useState();
    const [browserToken, setBrowserToken] = useState(localStorage.getItem("browserToken"));
    const [loading, setLoading] = useState(true);
    //const [cartUsersId, setCartUsersId] = useState(null);

    useEffect(() => {
        (async () => await tableLoad11())();
    }, []);

    async function tableLoad11(itemCat) {
        let datas
        if (itemCat) {
            datas = "?pageLayout[eq]=Product&productCatigory[eq]=" + itemCat + "&activeStatus[eq]=Active";
        } else {
            datas = "?pageLayout[eq]=Product&activeStatus[eq]=Active";
        }
        await axios.get("api/user/v1/post_page" + datas).then((res) => {
            // Gets the address of the backend server to be able to point the Images currectly
            if (res.data.status === 200) {
                //localStorage.setItem("bend", res.data.bend);

                setAllProducts(res.data.allData);
                console.log(res.data.bend);
            } else if (res.data.status === 401) {
                swal("Oop!", res.data.message, "warning");

            } else if (res.data.status === 500) {
                swal("Oop!", res.data.message, "warning");

            }
            else {
                swal("Warning", res.data.message, "warning");
                console.log(res.data.message);
                console.log(res.data.validation_error);

            }
        });

        if (!browserToken) {

            await axios.get("api/user/v1/browserToken").then((res) => {// Gets the address of the backend server to be able to point the Images currectly
                if (res.data.status === 200) {
                    localStorage.setItem("browserToken", res.data.browserTokens);
                    setBrowserToken(res.data.browserTokens);
                    console.log(res.data.bend);
                } else if (res.data.status === 401) {
                    swal("Oop!", res.data.message, "warning");
    
                } else if (res.data.status === 500) {
                    swal("Oop!", res.data.message, "warning");
    
                }
                else {
                    swal("Warning", res.data.message, "warning");
                    console.log(res.data.message);
                    console.log(res.data.validation_error);
    
                }
            });
        }

        //Gets all the Product Catigories
        let datae = "?type[eq]=productCategory&activeStatus[eq]=Active";
        await axios.get("api/user/v1/catigory" + datae).then((res) => {
            // Gets the address of the backend server to be able to point the Images currectly
            if (res.data.status === 200) {
                //localStorage.setItem("bend", res.data.bend);

                setAllCatigories(res.data.allData);
                console.log(res.data.bend);
            } else if (res.data.status === 401) {
                swal("Oop!", res.data.message, "warning");

            } else if (res.data.status === 500) {
                swal("Oop!", res.data.message, "warning");

            }
            else {
                swal("Warning", res.data.message, "warning");
                console.log(res.data.message);
                console.log(res.data.validation_error);

            }
        });



        axios.get("api/user/v1/general").then((res) => {// Gets Image server location
            if (res.data.status === 200) {
                //localStorage.setItem("bend", res.data.bend);
                setImageBends(res.data.bend);
                setLoading(false);
                console.log(res.data.bend);
            } else if (res.data.status === 401) {
                swal("Oop!", res.data.message, "warning");

            } else if (res.data.status === 500) {
                swal("Oop!", res.data.message, "warning");

            }
            else {
                swal("Warning", res.data.message, "warning");
                console.log(res.data.message);
                console.log(res.data.validation_error);

            }
        });

    }
    function numberWithCommas(x) {
        if(!x){x= 0;}
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const searchCats = (event) => {
        event.preventDefault();
        let allCats = event.currentTarget.dataset.value;
        tableLoad11(allCats);
    }

    /* End of Defualt Search Records */

    if (loading) {
        // Loading, Processing Payment, Please Dont Interrupt
        return <Loading message01={'Loading...'} />;
    }

    let deleyMotion = 1;

    return (
        <>
            {/* Product  */}
            <div className="container-xxl py-5">
                <div className="container">
                    <div className="section-title text-center">
                        {/* <h1 className="display-5 mb-5">Our Products</h1> */}
                    </div>
                    <div className="row mt-n2 wow fadeInUp" data-wow-delay="0.3s">
                        <div className="col-12 text-center">
                            <ul className="list-inline mb-5" id="portfolio-flters">
                                <li className="mx-2 active" data-filter="*" data-value='' onClick={searchCats}>All</li>
                                {allCatigories.map((allCatigorie, index) => {

                                    return <><li className="mx-2" data-value={allCatigorie.productCategory} onClick={searchCats}>{allCatigorie.productCategory}</li></>;
                                })}

                            </ul>
                        </div>
                    </div>
                    <div className="row g-4 portfolio-container">
                        {allProducts.map((allProduct, index) => {

                            async function addToCart(event) {

                                event.preventDefault();
                                let totalCost = 1 * allProduct.price;
                                const datas = {
                                    browserToken: browserToken,
                                    quantity: 1,
                                    totalCost: totalCost,
                                    unitCost: allProduct.price,
                                    itemId: allProduct.id,
                                    itemName: allProduct.pageTitle,
                                    status: 'checked',
                                    uploadFile: allProduct.uploadFile,
                                    childTrackNo: allProduct.childTrackNo,
                                    cartUsersId: localStorage.getItem("user_id"),
                                    productCatigory:allProduct.productCatigory,
                                };

                                await axios.get("sanctum/csrf-cookie").then((response) => {
                                    try {
                                        axios.post("api/user/v1/cart_page", datas).then((res) => {
                                            if (res.data.status === 200) {
                                                console.log(res.data.message);
                                                /*
                                                localStorage.setItem('auth_token', res.data.token);
                                                localStorage.setItem('auth_name', res.data.firstName);
                                                */
                                                swal("Success", res.data.message, "success");

                                                //navigate('/'); //redirect to the home page

                                            } else if (res.data.status === 401) {
                                                swal("Oop!", res.data.message, "warning");
                                
                                            } else if (res.data.status === 500) {
                                                swal("Oop!", res.data.message, "warning");
                                
                                            }
                                            else {
                                                swal("Warning", res.data.message, "warning");
                                                console.log(res.data.message);
                                                console.log(res.data.validation_error);
                                
                                            }
                                        });
                                    } catch (error) {
                                        swal("Oops!", "Item was not added to Cart", "error");
                                        console.log(error.response.data);
                                    }
                                });

                            }

                            deleyMotion = deleyMotion + 0.2;
                            return <>
                                <div className="col-lg-3 col-md-6 portfolio-item first wow fadeInUp" data-wow-delay={deleyMotion + 's'}>
                                    <div className="rounded overflow-hidden">
                                        <Link to={"/itemdetail/" + allProduct.id}>
                                            <div className="position-relative overflow-hidden">
                                                <img className="img-fluid w-100" src={imageBends + allProduct.uploadFile} alt="" />
                                            </div>
                                            <div className="border border-5 border-light border-top-0 p-4 text-center">
                                                <h6 className="lh-base mb-2">{allProduct.pageTitle}</h6>
                                                {(allProduct.price) ?
                                                    <>
                                                        <p className="text-primary fw-medium mb-2">₦{numberWithCommas(allProduct.price)}</p>
                                                    </> : ""}
                                                <Link className='btn btn-primary' onClick={addToCart}><i className="fa fa-shopping-cart me-1"></i>Add to Cart</Link>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </>
                        })}

                    </div>
                </div>
            </div>
            {/* Product End */}
        </>
    )
}

export default Product
