import React, { useEffect, useState } from 'react'
const Username = (props) => {


    const [titles, setTitles] = useState('');
    const [size, setSize] = useState(12);
    useEffect(() => {

        setTitles(props.loginTitle);
        setSize(props.size);
    }, [props.loginTitle, props.size]);

    return (
        <>
            <div className={"col-md-" + size}>
                <div className="form-floating">
                    <input type="email" disabled={props.inputState} className="form-control" id="loginEmail" placeholder="Email / Username" value={props.loginEmail}
                        onChange={(event) => {
                            props.setLoginEmail(event.target.value);
                        }}
                    />
                    <label for="loginEmail">{titles}</label>
                </div>
            </div>
        </>
    )
}

export default Username
