import React, { useEffect, useState } from 'react'
const CreateAcctPassword = (props) => {

    
    const [createAccounts, setCreateAccounts] = useState('none');
    const [titles, setTitles] = useState('');
    const [size, setSize] = useState(12);

    useEffect(() => {
        setTitles(props.loginTitle);
        setSize(props.size);
    }, [props.loginTitle, props.size]);

    return (
        <>
            <div className="col-md-12 mb-3">
                <div className="form-floating" style={{ display: "flex" }}>
                    <div>
                        <input
                            className="form-check-input"
                            id="createAccount"
                            type="checkbox"
                            value={createAccounts}
                            onChange={(event) => {
                                if (createAccounts === 'block') {
                                    setCreateAccounts('none');
                                } else {
                                    setCreateAccounts('block');
                                }
                            }}
                        />
                    </div>
                    <div className="ms-2">
                        <h6 for="createAccounts">Create an account?</h6 >
                    </div>
                </div>
            </div>

            <div className={"col-md-"+size} style={{ display: createAccounts }}  >
                <div className="form-floating">
                    <input type="password" disabled={props.inputState} className="form-control" id="password" placeholder="Your Password" value={props.password}
                        onChange={(event) => {
                            props.setPassword(event.target.value);
                        }}
                    />
                    <label for="password">{titles}</label>
                </div>
            </div>
        </>
    )
}

export default CreateAcctPassword
