import React from 'react'
import { Link } from "react-router-dom";
import { FaCartShopping } from "react-icons/fa6";
import { LuShip } from "react-icons/lu";
import { MdMarkEmailRead, MdOutlineRequestPage, MdOutlineBorderColor } from "react-icons/md";
import { IoSettingsOutline } from "react-icons/io5";

const DashboardNavbar = () => {
    return (
        <>
            {/* <!--  Start --> */}
            <div className="col-lg-3 p-lg-3 about-text p-3 bg-white wow fadeIn" data-wow-delay="0.5s">
                <div className="row m-3 mt-0">
                    <div className="col-12 m-3 pb-3 text-center border-bottom"><h6>My Account</h6></div>
                    <div className="col-12 m-2 text-center">
                        <Link to='/dashboard'> My Orders <MdOutlineBorderColor style={{ fontSize: '20px' }} />
                        </Link>
                    </div>
                    <div className="col-12 m-2 text-center">
                        <Link to={"/cart/" + localStorage.getItem("browserToken")}>My Cart<FaCartShopping style={{ fontSize: '20px' }} />
                        </Link>
                    </div>
                    <div className="col-12 m-2 text-center">
                        <Link to=''>Shipping Address<LuShip style={{ fontSize: '20px' }} />
                        </Link>
                    </div>
                    <div className="col-12 m-2 text-center">
                        <Link to='/contact'>Message Center<MdMarkEmailRead style={{ fontSize: '20px' }} />
                        </Link>
                    </div>
                    <div className="col-12 m-2 text-center">
                        <Link to='/quote'>Request Quote<MdOutlineRequestPage style={{ fontSize: '20px' }} />
                        </Link>
                    </div>
                    <div className="col-12 m-2 text-center">
                        <Link to=''>Settings<IoSettingsOutline style={{ fontSize: '20px' }} />
                        </Link>
                    </div>
                </div>
            </div>
            {/* <!--  End --> */}

        </>
    )
}

export default DashboardNavbar
