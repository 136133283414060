import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";
const Navbar = () => {
    const navigate = useNavigate("");
    const { checkoutid } = useParams();

    const [cartRecords, setCartRecords] = useState([]); //store defualt search records
    const [checkoutidToken, setcheckoutidToken] = useState({ checkoutid }.checkoutid);
    //const [reloadCount, setReloadCount] = useState();
    useEffect(() => {
        cartNumber();
    }, []);

    const cartNumber = () => {

        let dataser = "?cartUsersId[eq]=" + localStorage.getItem("user_id") + "&status[eq]=checked";
        axios.get("api/user/v1/cart_page" + dataser).then((res) => {
            // Gets the address of the backend server to be able to point the Images currectly
            if (res.data.status === 200) {
                setCartRecords(res.data.allData);
            } else if (res.data.status === 500) {
                let datases = "?browserToken[eq]=" + checkoutidToken + "&status[eq]=checked";
                console.log(res.data.validation_error);

                axios.get("api/user/v1/cart_page" + datases).then((ress) => {
                    if (ress.data.status === 200) {
                        setCartRecords(ress.data.allData);
                    } else if (ress.data.status === 500) {

                    }
                });
            }
        });
    }

    async function logoutSubmit(event) {
        event.preventDefault();

        await axios.get("sanctum/csrf-cookie").then((response) => {
            try {
                axios.post("api/user/v1/logouts").then((res) => {
                    if (res.data.status === 200) {
                        console.log(res.data.message);

                        localStorage.removeItem("user_name");
                        localStorage.removeItem("user_authority");
                        localStorage.removeItem("user_type");
                        localStorage.removeItem("user_status");
                        localStorage.removeItem("user_passport");
                        localStorage.removeItem("user_signature");
                        localStorage.removeItem("user_id");
                        localStorage.removeItem("auth_token");
                        localStorage.removeItem('auth_name');
                        localStorage.removeItem('bend');
                        localStorage.removeItem('totalcost');
                        localStorage.removeItem('browserToken');
                        localStorage.removeItem('payAmount');

                        /**/
                        cartNumber();
                        swal("Logout Successful", res.data.message, "success");
                        navigate("/logout"); //redirect to the Dashboard page
                    } else {
                        swal("Warning", res.data.message, "warning");
                        console.log(res.data.message);
                        console.log(res.data.validation_error);
                    }
                });
            } catch (error) {
                swal("Oops!", "Logout Failed", "error");
                console.log(error.response.data);
            }
        });
    }

    // setTimeout(function () {
    //     cartNumber();
    // }, 3000);

    return (
        <>
            {/* <!-- Topbar Start --> */}
            <div className="container-fluid bg-light p-0">
                <div className="row gx-0 d-none d-lg-flex">
                    <div className="col-lg-8 px-5 text-start">
                        <div className="h-100 d-inline-flex align-items-center py-3 me-3">
                            <small className="fa fa-map-marker-alt text-primary me-2"></small>
                            <small>Headquarters, Mabushi, Abuja</small>
                        </div>
                        <div className="h-100 d-inline-flex align-items-center me-3">
                            <small className="fa fa-phone-alt text-primary me-2"></small>
                            <small>(0)-9 122 0581 61</small>
                        </div>
                        <div className="h-100 d-none d-xl-inline-flex align-items-center py-3">
                            <small className="far fa-clock text-primary me-2"></small>
                            <small>Mon - Fri : 09.00AM - 05.00PM</small>
                        </div>
                    </div>
                    <div className="col-lg-2 px-1 text-start ">
                        <div className="h-100 d-inline-flex align-items-center ">
                            <Link className="btn btn-sm-square bg-white text-primary me-1" to=""><i className="fab fa-facebook-f"></i></Link>
                            <Link className="btn btn-sm-square bg-white text-primary me-1" to=""><i className="fab fa-twitter"></i></Link>
                            <Link className="btn btn-sm-square bg-white text-primary me-1" to=""><i className="fab fa-linkedin-in"></i></Link>
                            <Link className="btn btn-sm-square bg-white text-primary me-0" to=""><i className="fab fa-instagram"></i></Link>
                        </div>
                    </div>
                    <div className="col-lg-2 px-1 text-end ">
                        <div className="h-100 d-inline-flex text-start align-items-center py-3  me-3 ">
                            <small className="fa fa-user text-primary me-2"></small>
                            {localStorage.getItem("user_name") ? <small>Hi, {localStorage.getItem("user_name").substring(0, 10) + "..."}</small> : <small>Hi there, </small>}
                        </div>

                    </div>
                </div>
            </div>
            {/* <!-- Topbar End --> */}


            {/* <!-- Navbar Start --> */}
            <nav className="navbar navbar-expand-lg bg-white navbar-light sticky-top p-0">
                <Link to="/" className="navbar-brand d-flex align-items-center px-4 px-lg-5">
                    <h2 className="m-0 text-primary">Savvy Premier</h2>
                </Link>
                <button type="button" className="navbar-toggler me-4" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarCollapse">
                    <div className="navbar-nav ms-auto p-4 p-lg-0">
                        <Link to="/" className="nav-item nav-link active" onClick={cartNumber}>Home</Link>
                        <Link to="/aboutus" className="nav-item nav-link" onClick={cartNumber}>About</Link>
                        <Link to="/product" className="nav-item nav-link" onClick={cartNumber}>Products</Link>
                        <Link to="/contact" className="nav-item nav-link" onClick={cartNumber}>Contact</Link>
                        <div className="nav-item dropdown">
                            <Link to="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown"><i className="fa fa-user ms-2"></i> My Account</Link>
                            <div className="dropdown-menu fade-up m-0">
                                <Link to="/dashboard" className="dropdown-item" onClick={cartNumber}>My Orders</Link>
                                <Link to="/contact" className="dropdown-item" onClick={cartNumber}>Message Center</Link>
                                 <Link to="/quote" className="dropdown-item" onClick={cartNumber}>Request Quote</Link>
                                <Link to={"/cart/" + localStorage.getItem("browserToken")} className="dropdown-item" onClick={cartNumber}>
                                    Cart
                                    <i className="fa fa-shopping-cart ms-2">
                                        <span class="position-relative top-0 start-50 translate-middle badge rounded-pill bg-success">{cartRecords.length}
                                        </span>
                                    </i>
                                </Link>
                                {
                                    (!localStorage.getItem("user_id")) ? <Link to="/login" className="dropdown-item" onClick={cartNumber}>Login<i className="fa fa-power-off ms-2"></i>
                                    </Link> : <Link to="/logout" onClick={logoutSubmit} className="dropdown-item">Logout<i className="fa fa-power-off ms-2"></i>
                                    </Link>
                                }
                            </div>
                        </div>
                    </div>
                    <Link to={"/cart/" + localStorage.getItem("browserToken")} className="btn btn-primary py-4 px-lg-3 d-lg-block" onClick={cartNumber}>
                        Cart
                        <i className="fa fa-shopping-cart ms-2">
                            <span class="position-relative top-0 start-50 translate-middle badge rounded-pill bg-success">{cartRecords.length}
                            </span>
                        </i>
                    </Link>
                    {/* {
                        (!localStorage.getItem("user_id")) ? <Link to="/login" className="btn btn-success py-4 px-lg-3 d-lg-block" onClick={cartNumber}>Login<i className="fa fa-power-off ms-2"></i>
                        </Link> : <Link to="/logout" onClick={logoutSubmit} className="btn btn-danger py-4 px-lg-3 d-lg-block">Logout<i className="fa fa-power-off ms-2"></i>
                        </Link>
                    } */}


                </div>
            </nav>
            {/* <!-- Navbar End --> */}
        </>
    )
}

export default Navbar
