import React from "react";

import HomePage from "./HomePage";
const Faq = (props) => {

    return (
        <>
           <HomePage catigoryChild={props.catigoryChild} />;
                   
        </>
    )
}

export default Faq
