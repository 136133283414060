import React from "react";
import Team from "./childComponents/pageLayouts/Team";

const Teams = (props) => {
   
return (
    <>
        <Team  />

    </>
)
}

export default Teams
