import React, { useEffect, useState } from "react";
import axios from "axios";

import About from './childComponents/pageLayouts/About';
import Disclaimer from './childComponents/pageLayouts/Disclaimer';
import Quote from "./childComponents/pageLayouts/Quote";
import Service from "./childComponents/pageLayouts/Service";
import Team from "./childComponents/pageLayouts/Team";
import Product from "./childComponents/pageLayouts/Product";
import Testimonial from "./childComponents/pageLayouts/Testimonial";
import ChooseUs from "./childComponents/pageLayouts/ChooseUs";
import Contact from "./childComponents/pageLayouts/Contact";
import Loading from "./childComponents/appComponents/Loading";


const HomePage = (props) => {

    /* Defualt Page */
    const [allRecords, setAllRecords] = useState([]); //store defualt search records
    const [imageBend, setImageBend] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        (async () => await tableLoad())();
    }, []);

    async function tableLoad() {
        let datas = '?';
        if (props.catigoryChild) {
            datas = datas + "pageCatigory[eq]=" + props.catigoryChild + "&activeStatus[eq]=Active";
        }

        if (datas === '?') {
            datas = '';
        } else {

            await axios.get("api/user/v1/post_page" + datas).then((res) => {
                // Gets the address of the backend server to be able to point the Images currectly
                if (res.data.status === 200) {
                    //localStorage.setItem("bend", res.data.bend);

                    setAllRecords(res.data.allData);
                    console.log(res.data.bend);
                } else {
                    console.log(res.data.validation_error);
                }
            });



            axios.get("api/user/v1/general").then((res) => {// Gets the address of the backend server to be able to point the Images currectly
                if (res.data.status === 200) {
                    //localStorage.setItem("bend", res.data.bend);
                    setImageBend(res.data.bend);
                    setLoading(false);
                    console.log(res.data.bend);
                } else {
                    console.log(res.data.validation_error);
                }
            });

        }

    }
    /* End of Defualt Search Records */

    if (loading) {
        // Loading, Processing Payment, Please Dont Interrupt
        return <Loading message01={'Loading...'} />;
    }

    let tagName;

    return (
        <>
            {allRecords.map((allRecord, index) => {

                //Cart, About, Projects, Quote, Service, Team, Testimonial, ChooseUs, Contact, Product
                if (allRecord.activeStatus === 'Active') {

                    switch (allRecord.pageLayout) {
                        //switch ((allRecord.pageLayout) && (allRecord.activeStatus === 'Active')) {

                        case "About":
                            tagName = <About uploadFile={imageBend + allRecord.uploadFile} pageTitle={allRecord.pageTitle} message={allRecord.message} extra01={allRecord.extra01} extra02={allRecord.extra02} extra03={allRecord.extra03} extra04={allRecord.extra04} id={allRecord.id} />;

                            break;
                        case "Disclaimer":
                            tagName = <Disclaimer uploadFile={imageBend + allRecord.uploadFile} pageTitle={allRecord.pageTitle} message={allRecord.message} id={allRecord.id} />;

                            break;
                        
                        case "Quote":
                            tagName = <Quote uploadFile={imageBend + allRecord.uploadFile} pageTitle={allRecord.pageTitle} message={allRecord.message} extra01={allRecord.extra01} extra02={allRecord.extra02} extra03={allRecord.extra03} extra04={allRecord.extra04} id={allRecord.id} />;

                            break;
                        case "Service":
                            tagName = <Service uploadFile={imageBend + allRecord.uploadFile} pageTitle={allRecord.pageTitle} message={allRecord.message} extra01={allRecord.extra01} extra02={allRecord.extra02} extra03={allRecord.extra03} extra04={allRecord.extra04} id={allRecord.id} />;
                            break;
                        case "Team":
                            tagName = <Team uploadFile={imageBend + allRecord.uploadFile} pageTitle={allRecord.pageTitle} message={allRecord.message} extra01={allRecord.extra01} extra02={allRecord.extra02} extra03={allRecord.extra03} extra04={allRecord.extra04} id={allRecord.id} />;
                            break;
                        case "Product":
                            tagName = <Product uploadFile={imageBend + allRecord.uploadFile} pageTitle={allRecord.pageTitle} message={allRecord.message} extra01={allRecord.extra01} extra02={allRecord.extra02} extra03={allRecord.extra03} extra04={allRecord.extra04} id={allRecord.id} />;
                            break;
                        case "Testimonial":
                            tagName = <Testimonial uploadFile={imageBend + allRecord.uploadFile} pageTitle={allRecord.pageTitle} message={allRecord.message} extra01={allRecord.extra01} extra02={allRecord.extra02} extra03={allRecord.extra03} extra04={allRecord.extra04} id={allRecord.id} />;
                            break;
                        case "ChooseUs":
                            tagName = <ChooseUs uploadFile={imageBend + allRecord.uploadFile} pageTitle={allRecord.pageTitle} message={allRecord.message} extra01={allRecord.extra01} extra02={allRecord.extra02} extra03={allRecord.extra03} extra04={allRecord.extra04} id={allRecord.id} />;
                            break;
                        case "Contact":
                            tagName = <Contact uploadFile={imageBend + allRecord.uploadFile} pageTitle={allRecord.pageTitle} message={allRecord.message} extra01={allRecord.extra01} extra02={allRecord.extra02} extra03={allRecord.extra03} extra04={allRecord.extra04} id={allRecord.id} />;
                            break;
                        default:

                    }
                }

                return (
                    <>
                        {tagName}
                    </>

                )
            })}

        </>
    )

}

export default HomePage
