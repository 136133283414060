import React, { useEffect, useState } from 'react'
const Subject = (props) => {

    const [titles, setTitles] = useState('');
    const [size, setSize] = useState(12);
    useEffect(() => {
        
        setTitles(props.quoteTitle);
        setSize(props.size);
    }, [props.quoteTitle, props.size]);


    return (
        <>
            <div className={"col-md-"+size}>
                <input type="text" className="form-control" id="names" value={props.names} placeholder={titles} style={{ height: "55px" }} onChange={(event) => {
                    props.setNames(event.target.value);
                }} />
            </div>
        </>
    )
}

export default Subject
