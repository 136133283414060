import React, { useEffect, useState } from 'react'
const TextMessage = (props) => {

    
    const [titles, setTitles] = useState('');
    const [size, setSize] = useState(12);
    useEffect(() => {
        
        setTitles(props.quoteTitle);
        setSize(props.size);
    }, [props.quoteTitle, props.size]);

    

    return (
        <>
            <div className={"col-md-"+size}>
                <textarea className="form-control" id="textMessage" value={props.textMessage} placeholder={titles} style={{ height: "180px" }} onChange={(event) => {
                    props.setTextMessage(event.target.value);
                }}></textarea>
            </div>
        </>
    )
}

export default TextMessage
