import React, { useEffect, useState } from 'react'
const Subject = (props) => {

    const [titles, setTitles] = useState('');
    const [size, setSize] = useState(12);
    useEffect(() => {
        
        setTitles(props.quoteTitle);
        setSize(props.size);
    }, [props.quoteTitle, props.size]);

    

    return (
        <>
            <div className={"col-md-"+size}>
                <div className="form-floating">
                    <input type="text" className="form-control" id="subject" value={props.subject} placeholder={titles} style={{ height: "55px" }} onChange={(event) => {
                        props.setSubject(event.target.value);
                    }} />
                    <label for="subject">{titles}</label>
                </div>
            </div>
        </>
    )
}

export default Subject
