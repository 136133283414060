import React, { useEffect, useState } from 'react'
const RememberMe = (props) => {

    
    const [titles, setTitles] = useState('');
    const [size, setSize] = useState(6);
    useEffect(() => {
       
        setTitles(props.loginTitle);
        setSize(props.size);
    }, [props.loginTitle, props.size]);

    

    return (
        <>
            <div className={"col-md-"+size}>
                <div className="form-floating" style={{ display: "flex" }}>
                    <div><input
                        className="form-check-input"
                        id="rememberMe" disabled={props.inputState}
                        type="checkbox"
                        value={props.rememberMe}
                        onChange={(event) => {
                            if (props.rememberMe === true) {
                                props.setRememberMe(false);
                            } else {
                                props.setRememberMe(true);
                            }
                        }}
                    /></div>
                    <div className="ms-2"><label for="rememberMe">{titles}</label></div>

                </div>
            </div>
        </>
    )
}

export default RememberMe
