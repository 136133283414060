import React from 'react'
const ChooseUs = (props) => {

    function removeTags(str) {
        if ((str === null) || (str === ''))
            return false;
        else
            str = str.toString();
        return str.replace(/(<([^>]+)>)/ig, '');
    }

    return (
        <>
            {/* Choose Us  */}
            <div className="container-fluid bg-light overflow-hidden my-5 px-lg-0">
                <div className="container feature px-lg-0">
                    <div className="row g-0 mx-lg-0">
                        <div className="col-lg-6 feature-text py-5 wow fadeIn" data-wow-delay="0.5s">
                            <div className="p-lg-5 ps-lg-0">
                                <div className="section-title text-">
                                    <h1 className="display-5 mb-4">{removeTags(props.pageTitle)}</h1>
                                </div>
                                <p className="mb-4 pb-2">
                                    {(props.extra01) ?
                                        <>
                                            <div dangerouslySetInnerHTML={{ __html: props.message.substring(0, 800) + "..." }} />
                                        </> :
                                        <>
                                            <div dangerouslySetInnerHTML={{ __html: props.message.substring(0, 1600) + "..." }} />
                                        </>}
                                </p>
                                {(props.extra01 ?
                                    <>
                                        <div className="row g-4">
                                            <div className="col-6">
                                                <div className="d-flex align-items-center">
                                                    <div className="d-flex flex-shrink-0 align-items-center justify-content-center bg-white" style={{ width: "60px", height: "60px" }}>
                                                        <i className="fa fa-check fa-2x text-primary"></i>
                                                    </div>
                                                    <div className="ms-4">
                                                        <p className="mb-2">Quality</p>
                                                        <h5 className="mb-0">{props.extra01}</h5>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="d-flex align-items-center">
                                                    <div className="d-flex flex-shrink-0 align-items-center justify-content-center bg-white" style={{ width: "60px", height: "60px" }}>
                                                        <i className="fa fa-user-check fa-2x text-primary"></i>
                                                    </div>
                                                    <div className="ms-4">
                                                        <p className="mb-2">Creative</p>
                                                        <h5 className="mb-0">{props.extra02}</h5>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="d-flex align-items-center">
                                                    <div className="d-flex flex-shrink-0 align-items-center justify-content-center bg-white" style={{ width: "60px", height: "60px" }}>
                                                        <i className="fa fa-drafting-compass fa-2x text-primary"></i>
                                                    </div>
                                                    <div className="ms-4">
                                                        <p className="mb-2">Free</p>
                                                        <h5 className="mb-0">{props.extra03}</h5>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="d-flex align-items-center">
                                                    <div className="d-flex flex-shrink-0 align-items-center justify-content-center bg-white" style={{ width: "60px", height: "60px" }}>
                                                        <i className="fa fa-headphones fa-2x text-primary"></i>
                                                    </div>
                                                    <div className="ms-4">
                                                        <p className="mb-2">Customer</p>
                                                        <h5 className="mb-0">{props.extra04}</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </> : '')}
                            </div>
                        </div>
                        <div className="col-lg-6 pe-lg-0" style={{ minHeight: "400px" }}>
                            <div className="position-relative h-100">
                                {/* <img className="position-absolute img-fluid w-100 h-100" src={props.uploadFile} style={{ objectFit: "cover" }} alt="" /> */}
                                <img className="position-absolute img-fluid w-100" src={props.uploadFile} style={{ objectFit: "cover" }} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Choose Us */}
        </>
    )
}

export default ChooseUs
