import React, { useState } from 'react';
import Email from "../inputComponents/RequestQuoteContact/Email";
import Names from "../inputComponents/RequestQuoteContact/Names";
import Subject from "../inputComponents/RequestQuoteContact/Subject";
import Mobile from "../inputComponents/RequestQuoteContact/Mobile";
import TextMessage from "../inputComponents/RequestQuoteContact/TextMessage";
import CAPTCHATurnStile from "../appComponents/CAPTCHATurnStile";
import swal from "sweetalert";
import axios from "axios";
import { MdOutlineSendToMobile } from "react-icons/md";

const buttonName = 'Send Message';
const Contact = (props) => {
    const [names, setNames] = useState();
    const [email, setEmail] = useState();
    const [mobile, setMobile] = useState();
    const [subject, setSubject] = useState();
    const [textMessage, setTextMessage] = useState();
    const [loading, setLoading] = useState(buttonName); //to show loading process
    const [reCaptcha, setReCaptcha] = useState(false);

    const clearFields = () => {
        setNames('');
        setEmail('');
        setMobile('');
        setSubject('');
        setTextMessage('');
    }

    const submitContact = async (event) => {
        event.preventDefault();
        setLoading(<div>Sending...</div>);
        const datas = {
            names: names,
            email: email,
            subject: subject,
            mobile: mobile,
            textMessage: textMessage,
        };

        await axios.get("sanctum/csrf-cookie").then((response) => {
            try {
                axios.post("api/user/v1/contact_us", datas).then((res) => {
                    if (res.data.status === 200) {
                        console.log(res.data.message);
                        swal("Success", res.data.message, "success");
                        setLoading(buttonName);
                        clearFields();
                    } else if (res.data.status === 401) {
                        swal("Oop!", res.data.message, "warning");
                        setLoading(buttonName);
                    } else if (res.data.status === 500) {
                        swal("Oop!", res.data.message, "warning");
                        setLoading(buttonName);
                    }
                    else {
                        swal("Warning", res.data.message, "warning");
                        console.log(res.data.message);
                        console.log(res.data.validation_error);
                        setLoading(buttonName);
                    }
                });
            } catch (error) {
                swal("Oops!", "Failed", "error");
                setLoading(buttonName);
                console.log(error.response.data);

            }
        });
    }

    function removeTags(str) {
        if ((str === null) || (str === ''))
            return false;
        else
            str = str.toString();
        return str.replace(/(<([^>]+)>)/ig, '');
    }
    return (
        <>
            {/* <!-- Contact Start --> */}
            <div className="container-fluid bg-light overflow-hidden my-5 px-lg-0">
                <div className="container contact px-lg-0">
                    <div className="row g-0 mx-lg-0">
                        <div className="col-lg-6 contact-text py-5 wow fadeIn" data-wow-delay="0.5s">
                            <div className="p-lg-5 ps-lg-0">
                                <div className="section-title text-start">
                                    <h1 className="display-5 mb-4">{removeTags(props.pageTitle)}</h1>
                                </div>
                                <p className="mb-4"><div dangerouslySetInnerHTML={{ __html: props.message }} /></p>
                                <form>
                                    <div className="row g-3">
                                        <Names names={names} size='6' quoteTitle='Your Names' setNames={setNames} />

                                        <Email email={email} size='6' quoteTitle='Your Email' setEmail={setEmail} />

                                        <Mobile mobile={mobile} size='6' quoteTitle='Your Mobile' setMobile={setMobile} />

                                        <Subject subject={subject} size='6' quoteTitle='Subject' setSubject={setSubject} />

                                        <TextMessage textMessage={textMessage} size='12' quoteTitle='Overview of what you want' setTextMessage={setTextMessage} />

                                        <CAPTCHATurnStile reCaptcha={reCaptcha} setReCaptcha={setReCaptcha} />

                                        <div className="col-12">
                                            <button disabled={!reCaptcha} className="btn btn-primary w-100 py-3" type="submit" onClick={submitContact}>{loading} <MdOutlineSendToMobile style={{ fontSize: '20px' }} /></button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-lg-6 pe-lg-0" style={{ minHeight: "400px" }}>
                            <div className="position-relative h-100">

                                <iframe className="position-absolute w-100 h-100" style={{ objectFit: "cover" }}
                                    marginheight="0" marginwidth="0"
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3939.8241451708122!2d7.45204361529757!3d9.079779690690366!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x230ab879ddfe6cdc!2sFederal%20Ministry%20of%20Works%20and%20Housing!5e0!3m2!1sen!2sng!4v1628706990333!5m2!1sen!2sng"
                                    frameborder="0" allowfullscreen="" aria-hidden="false"
                                    tabindex="0" title="Map"></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Contact End --> */}
        </>
    )
}

export default Contact
