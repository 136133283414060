import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import Loading from "../appComponents/Loading";
import { useParams } from "react-router-dom";
const ItemsPurchaseDetail = () => {

    //itemdetails, orderdetails
    const { itemdetails } = useParams();
    const [itemDetailsIdToken, setItemDetailsIdToken] = useState({ itemdetails }.itemdetails);

    const { orderdetails } = useParams();
    const [orderDetailsIdToken, setOrderDetailsIdToken] = useState({ orderdetails }.orderdetails);
    //itemDetailsIdToken, orderDetailsIdToken

    const [loading, setLoading] = useState(true);

    const [cartRecords, setCartRecords] = useState([]); //store defualt search records
    const [imageBends, setImageBends] = useState();

    useEffect(() => {
        (async () => await tableLoad())();
    }, []);

    async function tableLoad() {
        let datas;
        if (itemDetailsIdToken) {
            datas = "?id[eq]=" + itemDetailsIdToken + "&paymentStatus[eq]=Paid";
        } else {
            datas = "?referenceNo[eq]=" + orderDetailsIdToken + "&paymentStatus[eq]=Paid";
        }

        await axios.get("api/user/v1/cart_page" + datas).then((res) => {
            // Gets the address of the backend server to be able to point the Images currectly
            if (res.data.status === 200) {
                //localStorage.setItem("bend", res.data.bend);

                setCartRecords(res.data.allData);
                console.log(res.data.bend);
            } else if (res.data.status === 401) {
                swal("Oop!", res.data.message, "warning");
            } else if (res.data.status === 500) {
                swal("Oop!", res.data.message, "warning");
            }
            else {
                swal("Warning", res.data.message, "warning");
                console.log(res.data.message);
                console.log(res.data.validation_error);
            }
        });


        axios.get("api/user/v1/general").then((res) => {
            // Gets the address of the backend server to be able to point the Images currectly
            if (res.data.status === 200) {
                //localStorage.setItem("bend", res.data.bend);
                setImageBends(res.data.bend);
                setLoading(false);
                console.log(res.data.bend);
            } else if (res.data.status === 401) {
                swal("Oop!", res.data.message, "warning");

            } else if (res.data.status === 500) {
                swal("Oop!", res.data.message, "warning");

            }
            else {
                swal("Warning", res.data.message, "warning");
                console.log(res.data.message);
                console.log(res.data.validation_error);

            }
        });

    }

    if (loading) {
        // Loading, Processing Payment, Please Dont Interrupt
        return <Loading message01={'Processing'} />;
    }
    let totalcost = 0;
    return (
        <>
            {cartRecords.length > 0 ?
                <>
                    <div className="col-lg-9 p-lg-3" style={{ minHeight: "400px" }}>
                        {cartRecords.map((cartRecord, index) => {
                            function numberWithCommas(x) {
                                if (!x) { x = 0; }
                                return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                            }
                            // setTotalcost(totalcost + cartRecord.totalCost);

                            const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

                            let d = new Date(cartRecord.created_at);
                            let month = months[d.getMonth()];
                            let day = d.getDate();
                            let year = d.getFullYear();
                            let completDate = day + ' ' + month + ', ' + year;

                            totalcost = totalcost + cartRecord.totalCost;
                            let paymentStatusIs;
                            if (cartRecord.status === 'pending') {
                                paymentStatusIs = 'Processing';
                            } else if (cartRecord.status === 'shipped') {
                                paymentStatusIs = 'Complete';
                            } else if (cartRecord.status === 'checked') {
                                paymentStatusIs = 'Pending Payment';
                            } else {
                                paymentStatusIs = 'Processing';
                            }

                            return <>
                                <div className="border-bottom p-2 mb-2">
                                    <div className="col-12 m-2" style={{ fontSize: "14px" }}>
                                        <div className="row">
                                            <div className="col-5"><h6>{paymentStatusIs}</h6></div>
                                            <div className="col-4 text-end">
                                                Order Date: {completDate}<br />
                                                Order ID: {cartRecord.referenceNo}
                                            </div>
                                            <div className="col-3 pe-4 text-end text-bold"><h6>Order Details</h6></div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-2 mb-2">
                                            <div style={{ fontSize: "14px" }} className="mb-2 text-center">
                                                {cartRecord.productCatigory}
                                            </div>
                                            <div>
                                                <Link to={"/itemdetail/" + cartRecord.itemId}><img className="img-fluid w-40" src={imageBends + cartRecord.uploadFile} style={{ objectFit: "cover" }} alt="" />
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="col-7">
                                            <Link to={"/itemdetail/" + cartRecord.itemId}>
                                                <div className="col-12">
                                                    <h6>{cartRecord.itemName}</h6>
                                                </div>

                                                <div className="col-12 pt-5">
                                                    Quantity: {cartRecord.quantity}
                                                </div>
                                                <div className="col-12">
                                                    Unit Cost: ₦{numberWithCommas(cartRecord.unitCost)}
                                                </div>
                                            </Link>

                                        </div>
                                        <div className="col-3 mb-2">
                                            <div className="text-center mt-4 ">
                                                <h6>₦{numberWithCommas(cartRecord.totalCost)}</h6>
                                            </div>
                                            <Link to={'/itemdetail/' + cartRecord.itemId} className="col-12 mt-2 btn btn-primary"><i className="fa fa-shopping-cart me-1"></i>Add to Cart</Link>
                                        </div>
                                    </div>
                                </div>
                            </>
                        })}
                    </div>
                </> :
                <>

                    <div className="col-lg-9 p-lg-3 text-center align-item-center" style={{ minHeight: "400px" }}>
                        <h3 className="align-item-center">EMPTY! ORDER</h3>
                        <i className="fa fa-shopping-cart p-3" style={{ fontSize: '15em' }}></i>
                        <Link to='/product'> <h5 className="btn btn-primary align-item-center">Return To Shopping</h5></Link>
                    </div>
                </>
            }
        </>
    )
}

export default ItemsPurchaseDetail
