import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Loading from "../appComponents/Loading";
import swal from "sweetalert";
const Team = (props) => {

    const [allStaffs, setAllStaffs] = useState([]); //store defualt search records

    const [imageBends, setImageBends] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        (async () => await tableLoad11())();
    }, []);

    async function tableLoad11(teamCat) {
        let datas

        datas = "?pageLayout[eq]=Team&activeStatus[eq]=Active";

        await axios.get("api/user/v1/post_page" + datas).then((res) => {
            // Gets the address of the backend server to be able to point the Images currectly
            if (res.data.status === 200) {
                //localStorage.setItem("bend", res.data.bend);

                setAllStaffs(res.data.allData);
                console.log(res.data.bend);
            } else if (res.data.status === 401) {
                swal("Oop!", res.data.message, "warning");

            } else if (res.data.status === 500) {
                swal("Oop!", res.data.message, "warning");

            }
            else {
                swal("Warning", res.data.message, "warning");
                console.log(res.data.message);
                console.log(res.data.validation_error);

            }
        });


        axios.get("api/user/v1/general").then((res) => {// Gets Image server location
            if (res.data.status === 200) {
                //localStorage.setItem("bend", res.data.bend);
                setImageBends(res.data.bend);
                setLoading(false);
                console.log(res.data.bend);
            } else if (res.data.status === 401) {
                swal("Oop!", res.data.message, "warning");

            } else if (res.data.status === 500) {
                swal("Oop!", res.data.message, "warning");

            }
            else {
                swal("Warning", res.data.message, "warning");
                console.log(res.data.message);
                console.log(res.data.validation_error);

            }
        });

    }


    if (loading) {
        // Loading, Processing Payment, Please Dont Interrupt
        return <Loading message01={'Loading...'} />;
    }

    let deleyMotion = 1;

    deleyMotion = deleyMotion + 0.2;
    return (
        <>
            {/* Team  */}
            <div className="container-xxl py-5">
                <div className="container">
                    <div className="section-title text-center">
                        <h1 className="display-5 mb-5">Team Members</h1>
                    </div>
                    <div className="row g-4">
                        {allStaffs.map((allStaff, index) => {

                            deleyMotion = deleyMotion + 0.2;
                            return <>
                                <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay={deleyMotion + 's'}>
                                    <div className="team-item">
                                        <Link to={"/teamdetail/" + allStaff.id}>
                                            <div className="overflow-hidden position-relative">
                                                <img className="img-fluid" src={imageBends + allStaff.uploadFile} alt="" />
                                                <div className="team-social">
                                                    <Link className="btn btn-square" target="_blank" to={allStaff.extra02}><i className="fab fa-facebook-f"></i></Link>
                                                    <Link className="btn btn-square" target="_blank" to={allStaff.extra03}><i className="fab fa-twitter"></i></Link>
                                                    <Link className="btn btn-square" target="_blank" to={allStaff.extra04}><i className="fab fa-instagram"></i></Link>
                                                </div>
                                            </div>
                                            <div className="text-center border border-5 border-light border-top-0 p-4">
                                                <h5 className="mb-0">{allStaff.extra01}</h5>
                                                <small>{allStaff.pageTitle}</small>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </>
                        })}
                    </div>
                </div>
            </div>
            {/* Team End */}
        </>
    )
}

export default Team
