import React from "react";
import Checkout from "./childComponents/pageLayouts/Checkout";

const Checkouts = () => {

    
    return (
        <>
            <Checkout />

        </>
    )
}

export default Checkouts
