import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";

import CAPTCHATurnStile from "../frontend/childComponents/appComponents/CAPTCHATurnStile";
import Email from "../frontend/childComponents/inputComponents/Checkouts/Email";
import FirstName from "../frontend/childComponents/inputComponents/Checkouts/FirstName";
import Password from "../frontend/childComponents/inputComponents/Checkouts/Password";
import RepeatPassword from "../frontend/childComponents/inputComponents/Checkouts/RepeatPassword";
import LastName from "../frontend/childComponents/inputComponents/Checkouts/LastName";
import LoginLink from "../frontend/childComponents/inputComponents/Checkouts/LoginLink";
import RegistrationLink from "../frontend/childComponents/inputComponents/Checkouts/RegistrationLink";
import OtpToken from "../frontend/childComponents/inputComponents/Checkouts/OtpToken";
import { RiLockPasswordLine } from "react-icons/ri";
const buttonName = ' Reset Password';
const Forgotpassword02 = () => {
  const navigate = useNavigate("");
  const [firstName, setFirstName] = useState(localStorage.getItem("firstName"));
  const [lastName, setLastName] = useState(localStorage.getItem("lastName"));
  const [registrationLink, setRegistrationLink] = useState();
  const [loginLink, setLoginLink] = useState();
  const [otpToken, setOtpToken] = useState();
  const [email, setEmail] = useState(localStorage.getItem("auth_email"));
  const [password, setPassword] = useState();
  const [repeatPassword, setRepeatPassword] = useState();
  const [reCaptcha, setReCaptcha] = useState(false);
  const [loading, setLoading] = useState(buttonName); //to show loading process


  async function resetPassword(event) {
    event.preventDefault();
    // setLoading(<div><i className="fa fa-refresh fa-spin"></i></div>);
    setLoading(<div>Loading...</div>);
    const datas = {
      otpToken: otpToken,
      //firstName: firstName,
      //lastName: lastName,
      email: email,
      password: password,
      repeatpassword: repeatPassword,
      //agree: agree,
    };

    await axios.get("sanctum/csrf-cookie").then((response) => {
      try {
        axios.post("api/user/v1/forgot_passwords/create", datas).then((res) => {
          if (res.data.status === 200) {
            console.log(res.data.message);
            /*
              localStorage.setItem('auth_token', res.data.token);
              localStorage.setItem('auth_name', res.data.firstName);
              */
            swal("Success", res.data.message, "success");
            setLoading(buttonName);
            navigate("/login"); //redirect to the home page
          } else {
            swal("Warning", res.data.message, "warning");
            setLoading(buttonName);
            console.log(res.data.message);
            console.log(res.data.validation_error);
          }
        });
      } catch (error) {
        swal("Oops!", "Registration Failed", "error");
        setLoading(buttonName);
        console.log(error.response.data);
      }
    });
  }
  return (

    <>
      <div className="container-fluid bg-light overflow-hidden my-5 px-lg-0">
        <div className="container quote px-lg-0">
          <div className="row g-0 mx-lg-0">
            <div className="col-lg-6 ps-lg-0" style={{ minHeight: "400px" }}>
              <div className="position-relative h-100">
                <img className="position-absolute img-fluid w-100" src="/assets/img/cybersecurity-mesh.jpg" style={{ objectFit: "cover" }} alt="" />
              </div>
            </div>
            <div className="col-lg-6 quote-text py-5 wow fadeIn" data-wow-delay="0.5s">
              <div className="p-lg-5 pe-lg-0">

                <h6 className='p-3'>Enter your email new password and we will reset it for you.</h6>
                <form>
                  <div className="row g-3">

                    <OtpToken otpToken={otpToken} size='6' loginTitle='OTP *' setOtpToken={setOtpToken} />

                    <Email email={email} size='6' inputState={'disabled'} loginTitle='Email address *' setEmail={setEmail} />

                    <FirstName firstName={firstName} size='6' inputState={'disabled'} loginTitle='First name *' setFirstName={setFirstName} />

                    <LastName lastName={lastName} size='6' inputState={'disabled'} loginTitle='Last name *' setLastName={setLastName} />

                    <Password password={password} size='6' loginTitle='Password *' setPassword={setPassword} />
                    <RepeatPassword repeatPassword={repeatPassword} size='6' loginTitle='Confirm Password *' setRepeatPassword={setRepeatPassword} />


                    <CAPTCHATurnStile reCaptcha={reCaptcha} setReCaptcha={setReCaptcha} />

                    <div className="col-md-12 mb-2">
                      <button
                        disabled={!reCaptcha} className="btn btn-primary col-md-12" onClick={resetPassword}
                      ><RiLockPasswordLine style={{ fontSize: '20px' }} /> 
                        {loading}
                      </button>
                    </div>
                    <RegistrationLink registrationLink={registrationLink} size='6' loginTitle='Need an account? Sign up!' setRegistrationLink={setRegistrationLink} />

                    <LoginLink loginLink={loginLink} size='6' loginTitle='Return to Login' setLoginLink={setLoginLink} />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Forgotpassword02;
