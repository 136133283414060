import React from "react";

import Quote from "./childComponents/pageLayouts/Quote";
const Quotes = (props) => {

    return (
        <>
           <Quote />;
                   
        </>
    )
}

export default Quotes
