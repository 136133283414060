import axios from "axios";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert";
import CAPTCHATurnStile from "../frontend/childComponents/appComponents/CAPTCHATurnStile";
import Email from "../frontend/childComponents/inputComponents/Checkouts/Email";
import Password from "../frontend/childComponents/inputComponents/Checkouts/Password";
import RepeatPassword from "../frontend/childComponents/inputComponents/Checkouts/RepeatPassword";
import ForgottenPasswordLink from "../frontend/childComponents/inputComponents/Checkouts/ForgottenPasswordLink";
import LoginLink from "../frontend/childComponents/inputComponents/Checkouts/LoginLink";
import OtpToken from "../frontend/childComponents/inputComponents/Checkouts/OtpToken";
import CountryRegion from "../frontend/childComponents/inputComponents/Checkouts/CountryRegion";
import StreetAddress from "../frontend/childComponents/inputComponents/Checkouts/StreetAddress";
import TownCity from "../frontend/childComponents/inputComponents/Checkouts/TownCity";
import State from "../frontend/childComponents/inputComponents/Checkouts/State";
import RememberMe from "../frontend/childComponents/inputComponents/Checkouts/RememberMe";
import { MdOutlineSwitchAccount } from "react-icons/md";
const buttonName = ' Create An Account';

const Register02 = () => {
  const { otp } = useParams();
  const navigate = useNavigate("");

  const [otpToken, setOtpToken] = useState({ otp }.otp);
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [repeatPassword, setRepeatPassword] = useState();
  const [rememberMe, setRememberMe] = useState(false);
  const [reCaptcha, setReCaptcha] = useState(false);

  const [phoneNumber, setPhoneNumber] = useState();
  const [countryRegion, setCountryRegion] = useState();
  const [state, setState] = useState();
  const [streetAddress, setStreetAddress] = useState();
  const [townCity, setTownCity] = useState();

  const [loading, setLoading] = useState(buttonName); //to show loading process
  const [forgottenPasswordLink, setForgottenPasswordLink] = useState();
  const [loginLink, setLoginLink] = useState();


  /* load the user email and phone number automatically */
  useEffect(() => {
    //setOtpToken({ otp }.otp);
    tableLoad();
  }, []);

  async function tableLoad() {

    try {
      axios.get("api/user/v1/verifyotps/" + otpToken).then((res) => {
        if (res.data.status === 200) {
          setEmail(res.data.email);
          setFirstName(res.data.firstName);
          setLastName(res.data.lastName);
          setPhoneNumber(res.data.phoneNumber);

        } else {
          setEmail(res.data.email);
          setFirstName(res.data.firstName);
          setLastName(res.data.lastName);
          setPhoneNumber(res.data.phoneNumber);

          swal("Warning", res.data.message, "warning");
          console.log(res.data.message, '$$$1Tesr');
          console.log(res.data.validation_error, '$$$2Tesr');
        }
      });
    } catch (error) {
      swal("Oops!", "404 Error", "error");
      console.log(error.response.data, '$$$3Tesr');
      setLoading(buttonName);
    }

  }
  //console.log(phoneNumber, '$$$test');
  /* End of load the user email and phone number automatically */
  async function createAccount(event) {
    event.preventDefault();
    // setLoading(<div><i className="fa fa-refresh fa-spin"></i></div>);
    setLoading(<div>Loading...</div>);

    const datas = {
      otpToken: otpToken,
      firstName: firstName,
      lastName: lastName,
      email: email,
      password: password,
      repeatpassword: repeatPassword,
      agree: rememberMe,

      phoneNumber: phoneNumber,
      streetAddress: streetAddress,
      townCity: townCity,
      state: state,
      countryRegion: countryRegion,

    };

    await axios.get("sanctum/csrf-cookie").then((response) => {
      try {
        axios.post("api/user/v1/registers/create", datas).then((res) => {
          if (res.data.status === 200) {
            console.log(res.data.message);

            localStorage.removeItem('auth_email');
            localStorage.removeItem('auth_phone');
            localStorage.removeItem('auth_FirstName');
            localStorage.removeItem('auth_LastName');

            swal("Success", res.data.message, "success");
            navigate("/login"); //redirect to the home page
            setLoading(buttonName);
          } else if (res.data.status === 401) {
            swal("Oop!", res.data.message, "warning");
            setLoading(buttonName);
          } else if (res.data.status === 500) {
            swal("Oop!", res.data.message, "warning");
            setLoading(buttonName);
          } else {
            swal("Warning", res.data.message, "warning");
            console.log(res.data.message);
            console.log(res.data.validation_error);
            setLoading(buttonName);
          }
        });
      } catch (error) {
        swal("Oops!", "Registration Failed", "error");
        console.log(error.response.data);
        setLoading(buttonName);
      }
    });

  }
  return (

    <>
      <div className="container-fluid bg-light overflow-hidden my-5 px-lg-0">
        <div className="container quote px-lg-0">
          <div className="row g-0 mx-lg-0">
            <div className="col-lg-6 ps-lg-0" style={{ minHeight: "400px" }}>
              <div className="position-relative h-100">
                <img className="position-absolute img-fluid w-100" src="/assets/img/cybersecurity-mesh.jpg" style={{ objectFit: "cover" }} alt="" />
              </div>
            </div>
            <div className="col-lg-6 quote-text py-5 wow fadeIn" data-wow-delay="0.5s">
              <div className="p-lg-5 pe-lg-0">

                {/* <h6 className='p-3'>Enter your email new password and we will reset it for you.</h6> */}
                <form>
                  <div className="row g-3">
                    <OtpToken otpToken={otpToken} inputState={'disabled'} size='6' loginTitle='OTP *' setOtpToken={setOtpToken} />

                    <Email email={email} size='6' inputState={'disabled'} loginTitle='Email address *' setEmail={setEmail} />

                    <Password password={password} size='6' loginTitle='Password *' setPassword={setPassword} />

                    <RepeatPassword repeatPassword={repeatPassword} size='6' loginTitle='Confirm Password *' setRepeatPassword={setRepeatPassword} />

                    <h6 className='pt-3 ps-3'>Enter your package delivery details.</h6>

                    <StreetAddress streetAddress={streetAddress} size='12' loginTitle='Street Address *' setStreetAddress={setStreetAddress} />

                    <TownCity townCity={townCity} size='6' loginTitle='Town / City *' setTownCity={setTownCity} />

                    <State state={state} size='6' loginTitle='State *' setState={setState} />

                    <CountryRegion countryRegion={countryRegion} size='12' loginTitle='First Name *' setCountryRegion={setCountryRegion} />

                    <RememberMe rememberMe={rememberMe} size='6' loginTitle='I agree with the terms and policy' setRememberMe={setRememberMe} />

                    <CAPTCHATurnStile reCaptcha={reCaptcha} setReCaptcha={setReCaptcha} />

                    <div className="col-md-12 mb-2">
                      <button
                        disabled={!reCaptcha} className="btn btn-primary col-md-12" onClick={createAccount}
                      ><MdOutlineSwitchAccount style={{ fontSize: '20px' }} /> 
                        {loading}
                      </button>
                    </div>
                    <ForgottenPasswordLink forgottenPasswordLink={forgottenPasswordLink} size='6' loginTitle='Forgotten Password' float='float-start' setForgottenPasswordLink={setForgottenPasswordLink} />

                    <LoginLink loginLink={loginLink} size='6' loginTitle='Return to Login' setLoginLink={setLoginLink} />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>

  );
};
export default Register02;
