import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";

import CAPTCHATurnStile from "../frontend/childComponents/appComponents/CAPTCHATurnStile";
import Email from "../frontend/childComponents/inputComponents/Checkouts/Email";
import ForgottenPasswordLink from "../frontend/childComponents/inputComponents/Checkouts/ForgottenPasswordLink";
import LoginLink from "../frontend/childComponents/inputComponents/Checkouts/LoginLink";
import PhoneNumber from "../frontend/childComponents/inputComponents/Checkouts/PhoneNumber";
import FirstName from "../frontend/childComponents/inputComponents/Checkouts/FirstName";
import LastName from "../frontend/childComponents/inputComponents/Checkouts/LastName";
import { FaRunning } from "react-icons/fa";
const buttonName = ' Request OPT';
const Register01 = () => {

  const navigate = useNavigate("");
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [email, setEmail] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [reCaptcha, setReCaptcha] = useState(false);
  const [loading, setLoading] = useState(buttonName); //to show loading process
  const [forgottenPasswordLink, setForgottenPasswordLink] = useState();
  const [loginLink, setLoginLink] = useState();

  async function resetPassword(event) {
    event.preventDefault();
    // setLoading(<div><i className="fa fa-refresh fa-spin"></i></div>);
    setLoading(<div>Loading...</div>);

    const datas = {
      email: email,
      firstName: firstName,
      lastName: lastName,
      phoneNumber: phoneNumber,
    };

    await axios.get("sanctum/csrf-cookie").then((response) => {
      try {
        axios
          .post("api/user/v1/accounts/validate", datas)
          .then((res) => {
            if (res.data.status === 200) {
              console.log(res.data.message, '$$$555');
              localStorage.setItem('auth_email', res.data.authEmail);
              localStorage.setItem('auth_phone', res.data.authPhone);
              localStorage.setItem('auth_FirstName', res.data.authFirstName);
              localStorage.setItem('auth_LastName', res.data.authLastName);

              swal("Success", res.data.message, "success");
              setLoading(buttonName);
              navigate("/register02"); //redirect to the home page
            } else if (res.data.status === 501) {

              swal("Caution", res.data.message, "warning");
              navigate("/register02"); //redirect to the home page
              setLoading(buttonName);
            } else {
              swal("Warning", res.data.message, "warning");
              console.log(res.data.message);
              console.log(res.data.validation_error);
              setLoading(buttonName);
            }
          });
      } catch (error) {
        swal("Oops!", "Validation Failed", "error");
        console.log(error.response.data);
        setLoading(buttonName);
      }
    });
  }
  return (
    <>
      <div className="container-fluid bg-light overflow-hidden my-5 px-lg-0">
        <div className="container quote px-lg-0">
          <div className="row g-0 mx-lg-0">
            <div className="col-lg-6 ps-lg-0" style={{ minHeight: "400px" }}>
              <div className="position-relative h-100">
                <img className="position-absolute img-fluid w-100" src="/assets/img/cybersecurity-mesh.jpg" style={{ objectFit: "cover" }} alt="" />
              </div>
            </div>
            <div className="col-lg-6 quote-text py-5 wow fadeIn" data-wow-delay="0.5s">
              <div className="p-lg-5 pe-lg-0">
                <h6 className='p-3'>Fill the details below and we'll send you an OPT, before you can continue with registration.</h6>
                <form>
                  <div className="row g-3">
                    <Email email={email} size='12' loginTitle='Email *' setEmail={setEmail} />

                    <FirstName firstName={firstName} size='6' loginTitle='First name *' setFirstName={setFirstName} />

                    <LastName lastName={lastName} size='6' loginTitle='Last name *' setLastName={setLastName} />

                    <PhoneNumber phoneNumber={phoneNumber} size='12' loginTitle='Phone Number *' setPhoneNumber={setPhoneNumber} />

                    <CAPTCHATurnStile reCaptcha={reCaptcha} setReCaptcha={setReCaptcha} />

                    <div className="col-md-12 mb-2">
                      <button
                        disabled={!reCaptcha} className="btn btn-primary col-md-12" onClick={resetPassword}
                      ><FaRunning style={{ fontSize: '20px' }} /> 
                        {loading}
                      </button>
                    </div>
                    <ForgottenPasswordLink forgottenPasswordLink={forgottenPasswordLink} size='6' loginTitle='Forgotten Password' float='float-start' setForgottenPasswordLink={setForgottenPasswordLink} />

                    <LoginLink loginLink={loginLink} size='6' loginTitle='Return to Login' setLoginLink={setLoginLink} />

                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>

  );
};
export default Register01;
