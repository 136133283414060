import React from 'react'

const Disclaimer = (props) => {

    function removeTags(str) {
        if ((str === null) || (str === ''))
            return false;
        else
            str = str.toString();
        return str.replace(/(<([^>]+)>)/ig, '');
    }

    return (
        <>
            {/* Disclaimer  */}
            {}
            <div className="container-fluid bg-light overflow-hidden my-5 px-lg-0">
                <div className="container about px-lg-0">
                    <div className="row g-0 mx-lg-0">
                        <div className="col-lg-6 ps-lg-0" style={{ minHeight: "400px" }}>
                            <div className="position-relative h-100">
                                {/* <img className="position-absolute img-fluid w-100 h-100" src={props.uploadFile} style={{ objectFit: "cover" }} alt="" /> */}
                                <img className="position-absolute img-fluid w-100" src={props.uploadFile} style={{ objectFit: "cover" }} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6 about-text py-5 wow fadeIn" data-wow-delay="0.5s">
                            <div className="p-lg-5 pe-lg-0">
                                <div className="section-title text-">
                                    <h1 className="display-5 mb-4">{removeTags(props.pageTitle)}</h1>
                                </div>
                                <p className="mb-4 pb-2 text-justify">
                                    
                                        
                                            <div dangerouslySetInnerHTML={{ __html: props.message}} />
                                        
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Disclaimer End */}
        </>
    )
}

export default Disclaimer
