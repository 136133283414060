import React from 'react'
import { Link } from "react-router-dom";
import { MdReadMore } from "react-icons/md";
const About = (props) => {

    function removeTags(str) {
        if ((str === null) || (str === ''))
            return false;
        else
            str = str.toString();
        return str.replace(/(<([^>]+)>)/ig, '');
    }

    function countWords(str) {
        return str.trim().split(/\s+/).length;
    }

    return (
        <>
            {/* About  */}
            {}
            <div className="container-fluid bg-light overflow-hidden my-5 px-lg-0">
                <div className="container about px-lg-0">
                    <div className="row g-0 mx-lg-0">
                        <div className="col-lg-6 ps-lg-0" style={{ minHeight: "400px" }}>
                            <div className="position-relative h-100">
                                {/* <img className="position-absolute img-fluid w-100 h-100" src={props.uploadFile} style={{ objectFit: "cover" }} alt="" /> */}
                                <img className="position-absolute img-fluid w-100" src={props.uploadFile} style={{ objectFit: "cover" }} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6 about-text py-5 wow fadeIn" data-wow-delay="0.5s">
                            <div className="p-lg-5 pe-lg-0">
                                <div className="section-title text-">
                                    <h1 className="display-5 mb-4">{removeTags(props.pageTitle)}</h1>
                                </div>
                                <p className="mb-4 pb-2 text-justify">
                                    {(props.extra01) ?
                                        <>
                                            <div dangerouslySetInnerHTML={{ __html: props.message.substring(0, 500) + "..." }} />
                                        </> :
                                        <>
                                            <div dangerouslySetInnerHTML={{ __html: props.message.substring(0, 1000) + "..." }} />
                                        </>}
                                </p>
                                {(props.extra01 ?
                                    <>
                                        <div className="row g-4 mb-4 pb-2">

                                            <div className="col-sm-6 wow fadeIn" data-wow-delay="0.1s">
                                                <div className="d-flex align-items-center">
                                                    <div className="d-flex flex-shrink-0 align-items-center justify-content-center bg-white" style={{ width: "60px; height: 60px" }}>
                                                        <i className="fa fa-users fa-2x text-primary"></i>
                                                    </div>{ }
                                                    <div className="ms-3">
                                                        <h2 className="text-primary mb-1" data-toggle="counter-up">{props.extra01}</h2>
                                                        <p className="fw-medium mb-0">Happy Clients</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 wow fadeIn" data-wow-delay="0.3s">
                                                <div className="d-flex align-items-center">
                                                    <div className="d-flex flex-shrink-0 align-items-center justify-content-center bg-white" style={{ width: "60px; height: 60px" }}>
                                                        <i className="fa fa-check fa-2x text-primary"></i>
                                                    </div>
                                                    <div className="ms-3">
                                                        <h2 className="text-primary mb-1" data-toggle="counter-up">{props.extra02}</h2>
                                                        <p className="fw-medium mb-0">Projects Done</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </> : '')}

                                {((countWords(removeTags(props.message)) > 70) && (props.extra01)) ?
                                    <><Link to={"/readmore/" + props.id} className="btn btn-primary py-3 px-5">Read More <MdReadMore style={{ fontSize: '20px' }} /></Link></> : ''
                                }

                                {((countWords(removeTags(props.message)) > 130) && (!props.extra01)) ?
                                    <><Link to={"/readmore/" + props.id} className="btn btn-primary py-3 px-5">Read More <MdReadMore style={{ fontSize: '20px' }} /></Link></> : ''
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* About End */}
        </>
    )
}

export default About
