import React from "react";
import Product from "./childComponents/pageLayouts/Product";

const Products = (props) => {
   
return (
    <>
        <Product  />

    </>
)
}

export default Products
