import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, Link } from "react-router-dom";
import swal from "sweetalert";
import Loading from "../appComponents/Loading";
import { MdOutlineShoppingCartCheckout } from "react-icons/md";
import { MdOutlineRequestQuote } from "react-icons/md";

const ItemDetail = () => {
    let buttonName = "Add To Cart";
    let quoteButton = "Request Quote";
    const { itemid } = useParams();
    const [itemidToken, setItemidToken] = useState({ itemid }.itemid);
    const [loading, setLoading] = useState(true);
    const [buttonNameLoading, setButtonNameLoading] = useState(buttonName);
    //const [cartUsersId, setCartUsersId] = useState(null);

    const [itemDetailRecords, setItemDetailRecords] = useState([]); //store defualt search records
    const [imageBends, setImageBends] = useState();
    const [quantity, setQuantity] = useState(0);
    const [browserToken, setBrowserToken] = useState(localStorage.getItem("browserToken"));

    useEffect(() => {
        (async () => await tableLoad())();
    }, []);

    async function tableLoad() {
        let datas;

        datas = "?id[eq]=" + itemidToken + "&activeStatus[eq]=Active";

        await axios.get("api/user/v1/post_page" + datas).then((res) => {
            // Gets the address of the backend server to be able to point the Images currectly
            if (res.data.status === 200) {
                //localStorage.setItem("bend", res.data.bend);

                setItemDetailRecords(res.data.allData);
                console.log(res.data.bend);
            } else if (res.data.status === 401) {
                swal("Oop!", res.data.message, "warning");

            } else if (res.data.status === 500) {
                swal("Oop!", res.data.message, "warning");

            }
            else {
                swal("Warning", res.data.message, "warning");
                console.log(res.data.message);
                console.log(res.data.validation_error);

            }
        });


        axios.get("api/user/v1/general").then((res) => {// Gets the address of the backend server to be able to point the Images currectly
            if (res.data.status === 200) {
                //localStorage.setItem("bend", res.data.bend);
                setImageBends(res.data.bend);
                setLoading(false);
                console.log(res.data.bend);
            } else if (res.data.status === 401) {
                swal("Oop!", res.data.message, "warning");

            } else if (res.data.status === 500) {
                swal("Oop!", res.data.message, "warning");

            }
            else {
                swal("Warning", res.data.message, "warning");
                console.log(res.data.message);
                console.log(res.data.validation_error);

            }
        });
    }

    if (loading) {
        // Loading, Processing Payment, Please Dont Interrupt
        return <Loading message01={'Loading...'} />;
    }
    return (
        <>
            {/* ItemDetail  */}
            <div className="container bg-light overflow-hidden my-5 px-lg-0">
                <div className="container about px-lg-0">

                    {itemDetailRecords.map((itemDetailRecord, index) => {
                        function numberWithCommas(x) {
                            if(!x){x= 0;}
                            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                        }

                        async function addToCart(event) {

                            event.preventDefault();

                            if (quantity > 0) {
                                if (!browserToken) {
                                    setButtonNameLoading(<div>Loading...</div>);

                                    await axios.get("api/user/v1/browserToken").then((res) => {// Gets 
                                        if (res.data.status === 200) {
                                            localStorage.setItem("browserToken", res.data.browserTokens);

                                            setBrowserToken(res.data.browserTokens);
                                            console.log(res.data.bend);
                                        } else if (res.data.status === 401) {
                                            swal("Oop!", res.data.message, "warning");
                            
                                        } else if (res.data.status === 500) {
                                            swal("Oop!", res.data.message, "warning");
                            
                                        }
                                        else {
                                            swal("Warning", res.data.message, "warning");
                                            console.log(res.data.message);
                                            console.log(res.data.validation_error);
                            
                                        }
                                    });
                                }

                                let totalCost = quantity * itemDetailRecord.price;
                                const datas = {
                                    browserToken: browserToken,
                                    quantity: quantity,
                                    totalCost: totalCost,
                                    unitCost: itemDetailRecord.price,
                                    itemId: itemDetailRecord.id,
                                    itemName: itemDetailRecord.pageTitle,
                                    status: 'checked',
                                    uploadFile: itemDetailRecord.uploadFile,
                                    childTrackNo: itemDetailRecord.childTrackNo,
                                    cartUsersId: localStorage.getItem("user_id"),
                                    productCatigory:itemDetailRecord.productCatigory,
                                };

                                await axios.get("sanctum/csrf-cookie").then((response) => {
                                    try {
                                        axios.post("api/user/v1/cart_page", datas).then((res) => {
                                            if (res.data.status === 200) {
                                                console.log(res.data.message);
                                                /*
                                                localStorage.setItem('auth_token', res.data.token);
                                                localStorage.setItem('auth_name', res.data.firstName);
                                                */

                                                setButtonNameLoading(buttonName);
                                                swal("Success", res.data.message, "success");

                                                //navigate('/'); //redirect to the home page

                                            } else if (res.data.status === 401) {
                                                swal("Oop!", res.data.message, "warning");
                                                setButtonNameLoading(buttonName);
                                            } else if (res.data.status === 500) {
                                                swal("Oop!", res.data.message, "warning");
                                                setButtonNameLoading(buttonName);
                                            } else {
                                                swal("Failed", res.data.message, "warning");
                                                setButtonNameLoading(buttonName);
                                                console.log(res.data.message);
                                                console.log(res.data.validation_error);
                                            }
                                        });
                                    } catch (error) {
                                        swal("Oops!", "Item was not added to Cart", "error");
                                        setButtonNameLoading(buttonName);
                                        console.log(error.response.data);
                                    }
                                });
                            }

                        }
                        return <>
                            <div className="row g-0 mx-lg-0">
                                <div className="col-lg-6 p-lg-5" style={{ minHeight: "400px" }}>

                                    <div className="position-relative h-100">

                                        <img className="position-absolute img-fluid w-100" src={imageBends + itemDetailRecord.uploadFile} style={{ objectFit: "cover" }} alt="" />
                                    </div>
                                </div>
                                <div className="col-lg-6 about-text p-5 wow fadeIn" data-wow-delay="0.5s">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th><h3>{itemDetailRecord.pageTitle}</h3></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <h4 className="text-danger">₦{numberWithCommas(itemDetailRecord.price)}
                                                    </h4>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><h5>Quantity:</h5>

                                                    <input type="number" id="quantity" min="0" className="form-control border-0" placeholder="0" value={quantity} style={{ height: "55px", width: "20%" }} onChange={(event) => {
                                                        setQuantity(event.target.value);
                                                    }} />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="accordion pt-5"><Link to="" className="btn btn-primary py-3 px-5" style={{ width: "100%" }} onClick={addToCart}><MdOutlineShoppingCartCheckout style={{ fontSize: '20px' }} /> {buttonNameLoading}</Link>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><Link to={"/quote"} className="btn btn-success py-3 px-5" style={{ width: "100%" }}><MdOutlineRequestQuote style={{ fontSize: '20px' }} /> {quoteButton}</Link></td>
                                            </tr>

                                            <tr>
                                                <div className="accordion pt-5" id="accordionExample">
                                                    <div className="accordion-item">
                                                        <h2 className="accordion-header" id="headingOne">
                                                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                                Item Description
                                                            </button>
                                                        </h2>
                                                        <div id="collapseOne" className="accordion-collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                            <div className="accordion-body">

                                                                <div dangerouslySetInnerHTML={{ __html: itemDetailRecord.message }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="accordion-item">
                                                        <h2 className="accordion-header" id="headingTwo">
                                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                                Item Specifications
                                                            </button>
                                                        </h2>
                                                        <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                                            <div className="accordion-body">
                                                                {itemDetailRecord.specification}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="accordion-item">
                                                        <h2 className="accordion-header" id="headingThree">
                                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                                Customer Reviews
                                                            </button>
                                                        </h2>
                                                        <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                                            <div className="accordion-body">
                                                                {itemDetailRecord.reviews}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div >
                        </>
                    })}




                </div>
            </div >
            {/* ItemDetail  */}
        </>
    )
}

export default ItemDetail
