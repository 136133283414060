import React from "react";
import TeamDetail from "./childComponents/pageLayouts/TeamDetail";

const TeamDetails = (props) => {

    
    return (
        <>
            <TeamDetail catigoryChild={props.catigoryChild}/>

        </>
    )
}

export default TeamDetails
