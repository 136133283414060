import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";

import CAPTCHATurnStile from "../frontend/childComponents/appComponents/CAPTCHATurnStile";
import Username from "../frontend/childComponents/inputComponents/Checkouts/Username";
import LPassword from "../frontend/childComponents/inputComponents/Checkouts/LPassword";
import ForgottenPasswordLink from "../frontend/childComponents/inputComponents/Checkouts/ForgottenPasswordLink";
import RegistrationLink from "../frontend/childComponents/inputComponents/Checkouts/RegistrationLink";
import RememberMe from "../frontend/childComponents/inputComponents/Checkouts/RememberMe";
import { CgLogIn } from "react-icons/cg";

const buttonName = ' Login';
const Login = () => {
  const navigate = useNavigate("");
  const [loginEmail, setLoginEmail] = useState();
  const [lPassword, setLPassword] = useState();
  const [rememberMe, setRememberMe] = useState(false);
  const [forgottenPasswordLink, setForgottenPasswordLink] = useState();
  const [registrationLink, setRegistrationLink] = useState();
  const [reCaptcha, setReCaptcha] = useState(false);
  const [loading, setLoading] = useState(buttonName); //to show loading process


  async function submitForm(event) {
    event.preventDefault();
    // setLoading(<div><i className="fa fa-refresh fa-spin"></i></div>);
    setLoading(<div>Loading...</div>);
    const datas = {
      email: loginEmail,
      password: lPassword,
      browserToken: localStorage.getItem("browserToken"),
    };

    await axios.get("sanctum/csrf-cookie").then((response) => {
      try {
        axios.post("api/user/v1/logins", datas).then((res) => {
          if (res.data.status === 200) {
            console.log(res.data.message);

            localStorage.setItem("user_name", res.data.user_name);
            localStorage.setItem("user_authority", res.data.user_authority);
            localStorage.setItem("user_type", res.data.user_type);
            localStorage.setItem("user_status", res.data.user_status);
            localStorage.setItem("user_passport", res.data.user_passport);
            localStorage.setItem("user_signature", res.data.user_signature);
            localStorage.setItem("user_id", res.data.user_id);
            localStorage.setItem("auth_token", res.data.token);
            localStorage.setItem("auth_name", res.data.user_name);
            localStorage.setItem("bend", res.data.bend);
            /*
              let resp = axios.get("api/user");
              console.log(resp.data, '$$$444');
            */
            swal("Login Successful", res.data.message, "success");
            setLoading(buttonName);
            navigate("/dashboard"); //redirect to the Dashboard page
          } else if (res.data.status === 401) {
            swal("Oop!", res.data.message, "warning");
            setLoading(buttonName);
          } else if (res.data.status === 500) {
            swal("Oop!", res.data.message, "warning");
            setLoading(buttonName);
          }
          else {
            swal("Warning", res.data.message, "warning");
            console.log(res.data.message);
            console.log(res.data.validation_error);
            setLoading(buttonName);
          }
        });
      } catch (error) {
        swal("Oops!", "Login Failed", "error");
        console.log(error.response.data);
        setLoading(buttonName);
      }
    });
  }
  return (
    <>
      <div className="container-fluid bg-light overflow-hidden my-5 px-lg-0">
        <div className="container quote px-lg-0">
          <div className="row g-0 mx-lg-0">
            <div className="col-lg-6 ps-lg-0" style={{ minHeight: "400px" }}>
              <div className="position-relative h-100">
                <img className="position-absolute img-fluid w-100" src="/assets/img/cybersecurity-mesh.jpg" style={{ objectFit: "cover" }} alt="" />
              </div>
            </div>
            <div className="col-lg-6 quote-text py-5 wow fadeIn" data-wow-delay="0.5s">
              <div className="p-lg-5 pe-lg-0">

                <h6 className='p-3'>Note: Username and password are case sensitive</h6>
                <form>
                  <div className="row g-3">
                    <Username loginEmail={loginEmail} size='12' loginTitle='Username *' setLoginEmail={setLoginEmail} />

                    <LPassword lPassword={lPassword} size='12' loginTitle='Password *' setLPassword={setLPassword} />

                    <RememberMe rememberMe={rememberMe} loginTitle='Remember me' setRememberMe={setRememberMe} />

                    <CAPTCHATurnStile reCaptcha={reCaptcha} setReCaptcha={setReCaptcha} />

                    <div className="col-md-12 mb-2">
                      <button
                        disabled={!reCaptcha} className="btn btn-primary col-md-12" onClick={submitForm}
                      ><CgLogIn style={{ fontSize: '20px' }} /> 
                        {loading}
                      </button>
                    </div>
                    <RegistrationLink registrationLink={registrationLink} size='6' loginTitle='Need an account? Sign up!' setRegistrationLink={setRegistrationLink} />

                    <ForgottenPasswordLink size='6' float='float-end' forgottenPasswordLink={forgottenPasswordLink} loginTitle='Forgotten Password' setForgottenPasswordLink={setForgottenPasswordLink} />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
