import React, { useEffect, useState } from 'react'
const PhoneNumber = (props) => {

  
    const [titles, setTitles] = useState('');
    const [size, setSize] = useState(12);
    useEffect(() => {
     
        setTitles(props.loginTitle);
        setSize(props.size);
    }, [props.loginTitle, props.size]);

    return (
        <>
            <div className={"col-md-"+size}>
                <div className="form-floating">
                    <input type="text" disabled={props.inputState} className="form-control" id="phoneNumber" placeholder="Your Phone Number" value={props.phoneNumber}
                        onChange={(event) => {
                            props.setPhoneNumber(event.target.value);
                        }}
                    />
                    <label for="phoneNumber">{titles}</label>
                </div>
            </div>
        </>
    )
}

export default PhoneNumber
