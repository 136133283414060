import React, { useState } from 'react';
import Email from "../inputComponents/RequestQuoteContact/Email";
import Names from "../inputComponents/RequestQuoteContact/Names";
import Mobile from "../inputComponents/RequestQuoteContact/Mobile";
import ProductCat from "../inputComponents/RequestQuoteContact/ProductCat";
import TextMessage from "../inputComponents/RequestQuoteContact/TextMessage";
import CAPTCHATurnStile from "../appComponents/CAPTCHATurnStile";
import swal from "sweetalert";
import axios from "axios";
import { MdOutlineSendToMobile } from "react-icons/md";

const buttonName = 'Send Message';
const Quote = () => {

    const [names, setNames] = useState();
    const [email, setEmail] = useState();
    const [mobile, setMobile] = useState();
    const [productCat, setProductCat] = useState();
    const [textMessage, setTextMessage] = useState();
    const [loading, setLoading] = useState(buttonName); //to show loading process
    const [reCaptcha, setReCaptcha] = useState(false);

    const clearFields = () => {
        setNames('');
        setEmail('');
        setMobile('');
        setProductCat('');
        setTextMessage('');
    }
    const submitQuote = async (event) => {
        event.preventDefault();
        setLoading(<div>Sending...</div>);
        const datas = {
            names: names,
            email: email,
            mobile: mobile,
            productCat: productCat,
            textMessage: textMessage,

        };

        await axios.get("sanctum/csrf-cookie").then((response) => {
            try {
                axios.post("api/user/v1/our_quotes", datas).then((res) => {
                    if (res.data.status === 200) {
                        console.log(res.data.message);
                        swal("Success", res.data.message, "success");
                        setLoading(buttonName);
                        clearFields();
                    } else if (res.data.status === 401) {
                        setLoading(buttonName);
                        swal("Oop!", res.data.message, "warning");
                    } else if (res.data.status === 500) {
                        setLoading(buttonName);
                        swal("Oop!", res.data.message, "warning");
                    }
                    else {
                        swal("Warning", res.data.message, "warning");
                        setLoading(buttonName);
                        console.log(res.data.message);
                        console.log(res.data.validation_error);
                    }
                });
            } catch (error) {
                swal("Oops!", "Failed", "error");
                setLoading(buttonName);
                console.log(error.response.data);
            }
        });
    }

    return (
        <>
            {/* Quote  */}
            <div className="container-fluid bg-light overflow-hidden my-5 px-lg-0">
                <div className="container quote px-lg-0">
                    <div className="row g-0 mx-lg-0">
                        <div className="col-lg-6 ps-lg-0" style={{ minHeight: "400px" }}>
                            <div className="position-relative h-100">
                                <img className="position-absolute img-fluid w-100" src="/assets/img/rfq.jpg" style={{ objectFit: "cover;" }} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6 quote-text py-5 wow fadeIn" data-wow-delay="0.5s">
                            <div className="p-lg-5 pe-lg-0">
                                <div className="section-title text-">
                                    <h1 className="display-5 mb-4">Request Quote</h1>
                                </div>
                                <h6 className='p-3'>Note: Your email address and number will not be published</h6>
                                <form>
                                    <div className="row g-3">
                                        <Names names={names} size='6' quoteTitle='Your Names' setNames={setNames} />

                                        <Email email={email} size='6' quoteTitle='Your Email' setEmail={setEmail} />

                                        <Mobile mobile={mobile} size='6' quoteTitle='Your Mobile' setMobile={setMobile} />

                                        <ProductCat productCat={productCat} size='6' quoteTitle='The Category' setProductCat={setProductCat} />

                                        <TextMessage textMessage={textMessage} quoteTitle='Overview of what you want' setTextMessage={setTextMessage} />

                                        <CAPTCHATurnStile reCaptcha={reCaptcha} setReCaptcha={setReCaptcha} />

                                        <div className="col-12">
                                            <button disabled={!reCaptcha} className="btn btn-primary w-100 py-3" onClick={submitQuote} type="submit">{loading} <MdOutlineSendToMobile style={{ fontSize: '20px' }} /></button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Quote End */}
        </>
    )
}

export default Quote
