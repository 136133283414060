import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, Link } from "react-router-dom";
import swal from "sweetalert";
import { MdShoppingCartCheckout } from "react-icons/md";
import Username from "../inputComponents/Checkouts/Username";
import LPassword from "../inputComponents/Checkouts/LPassword";
import RememberMe from "../inputComponents/Checkouts/RememberMe";
import ForgottenPasswordLink from "../inputComponents/Checkouts/ForgottenPasswordLink";
import FirstName from "../inputComponents/Checkouts/FirstName";
import LastName from "../inputComponents/Checkouts/LastName";
import CompanyName from "../inputComponents/Checkouts/CompanyName";
import CountryRegion from "../inputComponents/Checkouts/CountryRegion";
import StreetAddress from "../inputComponents/Checkouts/StreetAddress";
import TownCity from "../inputComponents/Checkouts/TownCity";
import State from "../inputComponents/Checkouts/State";
import PhoneNumber from "../inputComponents/Checkouts/PhoneNumber";
import Email from "../inputComponents/Checkouts/Email";
import CreateAcctPassword from "../inputComponents/Checkouts/CreateAcctPassword";
import Message from "../inputComponents/Checkouts/Message";
import Loading from "../appComponents/Loading";

const buttonName = 'Login';
const Checkout = () => {
    const { checkoutid } = useParams();

    const [checkoutidToken, setcheckoutidToken] = useState({ checkoutid }.checkoutid);
    const [rememberMe, setRememberMe] = useState(false);
    const [forgottenPasswordLink, setForgottenPasswordLink] = useState();
    const [loading, setLoading] = useState(true);
    const [loadingButton, setLoadingButton] = useState(buttonName); //to show loading process

    //setFirstName, setLastName, setCompanyName, setCountryRegion, setStreetAddress, setTownCity, setState, setPhoneNumber, setEmail, setCreateAccount, setPassword, setMessage / setlPassword, setLoginEmail

    const [lPassword, setLPassword] = useState();
    const [loginEmail, setLoginEmail] = useState();

    const [firstName, setFirstName] = useState();
    const [lastName, setLastName] = useState();
    const [companyName, setCompanyName] = useState();
    const [countryRegion, setCountryRegion] = useState();
    const [streetAddress, setStreetAddress] = useState();
    const [townCity, setTownCity] = useState();
    const [state, setState] = useState();
    const [phoneNumber, setPhoneNumber] = useState();
    const [email, setEmail] = useState();
    const [otpTime, setOtpTime] = useState();
    const [otp, setOtp] = useState('none');
    const [password, setPassword] = useState();
    const [message, setMessage] = useState();


    // const [successState, setSuccessState] = useState();
    // console.log(successState, '$$$DDD');
    const [checkoutRecords, setCheckoutRecords] = useState([]); //store defualt search records
    //const [userRecords, setUserRecords] = useState([]); //user defualt search records

    useEffect(() => {
        (async () => await tableLoad())();
    }, []);

    async function tableLoad() {
        let datas;

        datas = "?browserToken[eq]=" + checkoutidToken + "&status[eq]=checked";
        if (localStorage.getItem("user_id")) {
            datas = "?cartUsersId[eq]=" + localStorage.getItem("user_id") + "&status[eq]=checked";
        }

        await axios.get("api/user/v1/cart_page" + datas).then((res) => {
            // Gets the address of the backend server to be able to point the Images currectly
            if (res.data.status === 200) {

                setCheckoutRecords(res.data.allData);
                console.log(res.data.bend);
            } else if (res.data.status === 401) {
                swal("Oop!", res.data.message, "warning");

            } else if (res.data.status === 500) {
                swal("Oop!", res.data.message, "warning");

            }
            else {
                swal("Warning", res.data.message, "warning");
                console.log(res.data.message);
                console.log(res.data.validation_error);

            }
        });

        datas = "?id[eq]=" + localStorage.getItem("user_id") + "&status[eq]=Active";

        await axios.get("api/user/v1/cart_user" + datas).then((res) => {
            if (res.data.status === 200) {
                console.log(res.data.message);

                res.data.allData.map((userRecord, index) => {
                    //console.log(userRecord, '$$$rr');
                    setFirstName(userRecord.firstName);
                    setLastName(userRecord.lastName);
                    setCompanyName(userRecord.companyName);
                    setCountryRegion(userRecord.countryRegion);
                    setStreetAddress(userRecord.streetAddress);
                    setTownCity(userRecord.townCity);
                    setState(userRecord.state);
                    setPhoneNumber(userRecord.phoneNumber);
                    setEmail(userRecord.email);
                    return <></>;
                });

            } else if (res.data.status === 401) {
                swal("Oop!", res.data.message, "warning");

            } else if (res.data.status === 500) {
                swal("Oop!", res.data.message, "warning");

            }
            else {
                swal("Warning", res.data.message, "warning");
                console.log(res.data.message);
                console.log(res.data.validation_error);

            }
        });



        axios.get("api/user/v1/general").then((res) => {
            // Gets the address of the backend server to be able to point the Images currectly
            if (res.data.status === 200) {
                //localStorage.setItem("bend", res.data.bend);
                setLoading(false);
                console.log(res.data.bend);
            } else if (res.data.status === 401) {
                swal("Oop!", res.data.message, "warning");

            } else if (res.data.status === 500) {
                swal("Oop!", res.data.message, "warning");

            }
            else {
                swal("Warning", res.data.message, "warning");
                console.log(res.data.message);
                console.log(res.data.validation_error);

            }
        });

    }
    function numberWithCommas(x) {
        if (!x) { x = 0; }
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    async function buyProduct(event) {
        event.preventDefault();

        setOtp(Math.floor(Math.random() * (5400 - 1200)) + 1200);
        setOtpTime();

        const buyProduct = {
            firstName: firstName,
            lastName: lastName,
            companyName: companyName,
            countryRegion: countryRegion,
            streetAddress: streetAddress,
            townCity: townCity,
            state: state,
            phoneNumber: phoneNumber,
            email: email,
            password: password,
            message: message,
            user_id: localStorage.getItem("user_id"),
            repeatpassword: password,
            otp: otp,
            otpTime: otpTime,
            browserToken: localStorage.getItem("browserToken"),
        };

        await axios.get("sanctum/csrf-cookie").then((response) => {
            try {
                axios.post("api/user/v1/buy_products", buyProduct).then((res) => {
                    if (res.data.status === 200) {

                        if (res.data.user_name) {
                            localStorage.setItem("payAmount", res.data.payAmount);
                            localStorage.setItem("user_name", res.data.user_name);
                            localStorage.setItem("user_authority", res.data.user_authority);
                            localStorage.setItem("user_type", res.data.user_type);
                            localStorage.setItem("user_status", res.data.user_status);
                            localStorage.setItem("user_passport", res.data.user_passport);
                            localStorage.setItem("user_signature", res.data.user_signature);
                            localStorage.setItem("user_id", res.data.user_id);
                            localStorage.setItem("auth_token", res.data.token);
                            localStorage.setItem("auth_name", res.data.user_name);
                            localStorage.setItem("bend", res.data.bend);
                            localStorage.setItem("referenceNo", res.data.referenceNo);
                        }
                        else {
                            localStorage.setItem("payAmount", res.data.payAmount);
                            localStorage.setItem("referenceNo", res.data.referenceNo);
                        }
                        console.log(res.data.message, '$$$Testdd');
                        let datas = JSON.parse(res.data.message);//convert Json to an Object
                        console.log(datas.data.authorization_url, '$$$Testdd');
                        window.location.href = datas.data.authorization_url;
                    } else if (res.data.status === 401) {
                        swal("Oop!", res.data.message, "warning");

                    } else if (res.data.status === 500) {
                        swal("Oop!", res.data.message, "warning");

                    }
                    else {
                        swal("Warning", res.data.message, "warning");
                        console.log(res.data.message);
                        console.log(res.data.validation_error);

                    }
                });
            } catch (error) {
                swal("Oops!", "Failed", "error");
            }
        });

    }
    const loginAccount = async (event) => {
        event.preventDefault();
        //lPassword, loginEmail
        setLoadingButton(<div>Loading...</div>);
        setLoading(true);
        const datas = {
            email: loginEmail,
            password: lPassword,
            browserToken: localStorage.getItem("browserToken"),
        };

        await axios.get("sanctum/csrf-cookie").then((response) => {
            try {
                axios.post("api/user/v1/logins", datas).then((res) => {
                    if (res.data.status === 200) {
                        console.log(res.data.message);

                        localStorage.setItem("user_name", res.data.user_name);
                        localStorage.setItem("user_authority", res.data.user_authority);
                        localStorage.setItem("user_type", res.data.user_type);
                        localStorage.setItem("user_status", res.data.user_status);
                        localStorage.setItem("user_passport", res.data.user_passport);
                        localStorage.setItem("user_signature", res.data.user_signature);
                        localStorage.setItem("user_id", res.data.user_id);
                        localStorage.setItem("auth_token", res.data.token);
                        localStorage.setItem("auth_name", res.data.user_name);
                        localStorage.setItem("bend", res.data.bend);

                        setLoadingButton(buttonName);
                        tableLoad();
                        setLoading(false);

                    } else if (res.data.status === 401) {
                        swal("Oop!", res.data.message, "warning");
                        setLoadingButton(buttonName);
                    } else if (res.data.status === 500) {
                        swal("Oop!", res.data.message, "warning");
                        setLoadingButton(buttonName);
                        setLoading(false);
                    }
                    else {
                        swal("Warning", res.data.message, "warning");
                        console.log(res.data.message);
                        console.log(res.data.validation_error);
                        setLoadingButton(buttonName);
                        setLoading(false);
                    }
                });
            } catch (error) {
                swal("Oops!", "Failed", "error");
                console.log(error.response.data);
                setLoadingButton(buttonName);
                setLoading(false);
            }
        });

    }

    if (loading) {
        // Loading, Processing Payment, Please Dont Interrupt
        return <Loading message01={'Processing'} />;
    }
    let totalcost = 0;
    return (
        <>

            {/* Checkout  */}
            <div className="container bg-light overflow-hidden my-5 px-lg-0">
                <div className="container about px-lg-0">
                    <div className="row g-0 mx-lg-0">

                        <div className="col-lg-7 px-5" style={{ minHeight: "400px" }}>
                            <div className="accordion" id="accordionExample">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingOne">
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                            I Already Have An Account
                                        </button>
                                    </h2>
                                    <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <form>
                                                <div className="row g-3">
                                                    <Username loginEmail={loginEmail} size='6' loginTitle='Your Email *' setLoginEmail={setLoginEmail} />

                                                    <LPassword lPassword={lPassword} size='6' loginTitle='Password *' setLPassword={setLPassword} />

                                                    <div className="col-md-12 mb-2">
                                                        <button className="btn btn-primary col-md-12" onClick={loginAccount}>{loadingButton}</button>
                                                    </div>

                                                    <RememberMe rememberMe={rememberMe} size='6' loginTitle='Remember me' setRememberMe={setRememberMe} />

                                                    <ForgottenPasswordLink
                                                        float='float-end' size='6'
                                                        forgottenPasswordLink={forgottenPasswordLink} loginTitle='Forgotten Password' setForgottenPasswordLink={setForgottenPasswordLink} />

                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingTwo">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                                            Billing Details
                                        </button>
                                    </h2>
                                    <div id="collapseTwo" className="accordion-collapse show" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <form>
                                                <div className="row g-3">
                                                    <FirstName firstName={firstName} size='6' loginTitle='First Name *' setFirstName={setFirstName} />

                                                    <LastName lastName={lastName} size='6' loginTitle='Last Name *' setLastName={setLastName} />

                                                    <PhoneNumber phoneNumber={phoneNumber} size='6' loginTitle='Phone Number *' setPhoneNumber={setPhoneNumber} />

                                                    <CompanyName companyName={companyName} size='6' loginTitle='Company name (optional)' setCompanyName={setCompanyName} />

                                                    <StreetAddress streetAddress={streetAddress} size='12' loginTitle='Street Address *' setStreetAddress={setStreetAddress} />

                                                    <TownCity townCity={townCity} size='6' loginTitle='Town / City *' setTownCity={setTownCity} />

                                                    <State state={state} size='6' loginTitle='State *' setState={setState} />

                                                    <CountryRegion countryRegion={countryRegion} size='12' loginTitle='First Name *' setCountryRegion={setCountryRegion} />

                                                    <Email email={email} size='12' loginTitle='Your Email *' setEmail={setEmail} />

                                                    {localStorage.getItem("user_id") ? '' : <CreateAcctPassword password={password} size='12' loginTitle='Your Password *' setPassword={setPassword} />}


                                                    <Message message={message} size='12' loginTitle='Order notes (optional)' setMessage={setMessage} />

                                                </div>

                                            </form>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="col-lg-5 p-lg-3 about-text p-3 bg-white wow fadeIn" data-wow-delay="0.5s">
                            <div className="row m-3 mt-0">
                                <div className="row">
                                    <div className="col-7 text-start border-bottom">
                                        <h6>Product</h6>
                                    </div>
                                    <div className="col-2 text-start border-bottom">
                                        <h6>Quantity</h6>
                                    </div>
                                    <div className="col-3 text-end border-bottom">
                                        <h6>Subtotal</h6>
                                    </div>
                                </div>
                                {checkoutRecords.map((cartRecord, index) => {
                                    function numberWithCommas(x) {
                                        if (!x) { x = 0; }
                                        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                                    }
                                    // setTotalcost(totalcost + cartRecord.totalCost);

                                    totalcost = totalcost + cartRecord.totalCost;
                                    return <>
                                        <div className="row">
                                            <div className="col-7 text-start py-2">
                                                <Link to={"/itemdetail/" + cartRecord.itemId}>
                                                    {cartRecord.itemName}
                                                </Link>
                                            </div>
                                            <div className="col-2 text-start py-2">
                                                {cartRecord.quantity}
                                            </div>
                                            <div className="col-3 text-end py-2">
                                                ₦{numberWithCommas(cartRecord.totalCost)}
                                            </div>
                                        </div>
                                    </>
                                })}
                                <div className="row">
                                    <div className="col-9 text-start py-2">
                                        <h6>Subtotal</h6>
                                    </div>
                                    <div className="col-3 text-end py-2">
                                        ₦{numberWithCommas(totalcost)}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-9 text-start py-2">
                                        <h6>Shipping</h6>
                                    </div>
                                    <div className="col-3 text-end py-2">
                                        ₦{numberWithCommas(5000)}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-9 text-start py-2">
                                        <h6>Total</h6>
                                    </div>
                                    <div className="col-3 text-end py-2">
                                        ₦{numberWithCommas(totalcost + 5000)}
                                        {localStorage.setItem('totalcost', totalcost + 5000)}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 text-start py-2">
                                        <button className="btn btn-primary py-2 px-3 col-12" onClick={buyProduct}>Checkout <MdShoppingCartCheckout style={{ fontSize: '20px' }} /></button>
                                    </div>

                                </div>
                            </div>
                        </div>





                    </div>
                </div >
            </div >
            {/* Checkout  */}
        </>
    )
}

export default Checkout
