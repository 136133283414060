import React from 'react'
import { Link } from "react-router-dom";
const Footer = () => {
    return (
        <>
            {/* <!-- Footer Start --> */}
            <div className="container-fluid bg-dark text-light footer mt-5 pt-5 wow fadeIn" data-wow-delay="0.1s">
                <div className="container py-5">
                    <div className="row g-5">
                        <div className="col-lg-3 col-md-6">
                            <h4 className="text-light mb-4">Address</h4>
                            <p className="mb-2"><i className="fa fa-map-marker-alt me-3"></i>Federal Ministry of Works, Headquarters, Mabushi, Abuja</p>
                            <p className="mb-2"><i className="fa fa-phone-alt me-3"></i>Nigeria: 234-(0)- 9 122 0581 61</p>
                            <p className="mb-2"><i className="fa fa-envelope me-3"></i>Enquiries: info@fmw.gov.ng</p>
                            <div className="d-flex pt-2">
                                <Link className="btn btn-outline-light btn-social" to=""><i className="fab fa-twitter"></i></Link>
                                <Link className="btn btn-outline-light btn-social" to=""><i className="fab fa-facebook-f"></i></Link>
                                <Link className="btn btn-outline-light btn-social" to=""><i className="fab fa-youtube"></i></Link>
                                <Link className="btn btn-outline-light btn-social" to=""><i className="fab fa-linkedin-in"></i></Link>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <h4 className="text-light mb-4">Catigories</h4>
                            <Link className="btn btn-link" to="/product">Inverter</Link>
                            <Link className="btn btn-link" to="/product">Gel Battery</Link>
                            <Link className="btn btn-link" to="/product">Lithium Battery</Link>
                            <Link className="btn btn-link" to="/product">Solar Panel</Link>
                            <Link className="btn btn-link" to="/product">Floor Light</Link>
                            <Link className="btn btn-link" to="/product">Charge Controller</Link>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <h4 className="text-light mb-4">Quick Links</h4>
                            <Link className="btn btn-link" to="/disclaimer">Disclaimer</Link>
                            <Link className="btn btn-link" to="/privacypolicy">Privacy Policy</Link>
                            <Link className="btn btn-link" to="/termsofuse">Terms of Use</Link>
                            <Link className="btn btn-link" to="/quote">Quote</Link>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <h4 className="text-light mb-4">Payment Methods</h4>
                            <p>We accept direct bank transfer payments or you can pay with any of the Credit or Debit cards.</p>
                            <div className="position-relative mx-auto d-flex" style={{ maxWidth: "400px" }}>
                                <span className='col-4'><img src="assets/img/11.png" width={'100%'} alt="" /></span>
                                <span className='col-4'><img src="assets/img/12.png" width={'100%'} alt="" /></span>
                                <span className='col-4'><img src="assets/img/13.png" width={'100%'} alt="" /></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="copyright">
                        <div className="row">
                            <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                                &copy; <Link className="border-bottom" to="#">Savvy Premier Ltd</Link>, All Right Reserved.
                            </div>
                            <div className="col-md-6 text-center text-md-end">
                                {/* <!-- This template is free as long as you keep the footer author’s credit link/attribution link/backlink. If you'd like to use the template without the footer author’s credit link/attribution link/backlink, you can purchase the Credit Removal License from "https://htmlcodex.com/credit-removal". Thank you for your support. --> */}
                                Designed By <Link className="border-bottom" to="#">CViewSoft Ltd</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Footer End --> */}


            {/* <!-- Back to Top --> */}
            <Link to="#" className="btn btn-lg btn-primary btn-lg-square rounded-0 back-to-top"><i className="bi bi-arrow-up"></i></Link>

        </>
    )
}

export default Footer
