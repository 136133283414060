import React from "react";
import Cart from "./childComponents/pageLayouts/Cart";

const Carts = () => {

    
    return (
        <>
            <Cart />

        </>
    )
}

export default Carts
