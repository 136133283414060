import React, { useEffect, useState } from 'react';
import ReactFlagsSelect from "react-flags-select";
const CountryRegion = (props) => {

    const [titles, setTitles] = useState('');
    const [size, setSize] = useState(12);

    useEffect(() => {
        setTitles(props.loginTitle);
        setSize(props.size);
    }, [props.loginTitle, props.size]);

    return (
        <>
            <div className={"bg-white col-md-"+size} >
                <div className="form-floating">
                    <ReactFlagsSelect
                        selected={props.countryRegion}
                        onSelect={(code) => props.setCountryRegion(code)}
                        placeholder="Select Country"
                        searchable
                        searchPlaceholder="Your Country / Region"
                        className="menu-flags"
                    />
                </div>
            </div>
        </>
    )
}

export default CountryRegion
