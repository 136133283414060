import React, { useEffect, useState } from 'react';
import axios from "axios";
const ProductCat = (props) => {

    const [titles, setTitles] = useState('');
    const [allCatigories, setAllCatigories] = useState([]); //store defualt search records
    const [size, setSize] = useState(12);
    useEffect(() => {
        
        setTitles(props.quoteTitle);
        setSize(props.size);
    }, [props.quoteTitle, props.size]);


    useEffect(() => {
        (async () => await tableLoad11())();
    }, []);

    async function tableLoad11() {
        //Gets all the Product Catigories
        let datae = "?type[eq]=productCategory&activeStatus[eq]=Active";

        await axios.get("api/user/v1/catigory" + datae).then((res) => {
            if (res.data.status === 200) {
                //localStorage.setItem("bend", res.data.bend);
                
                setAllCatigories(res.data.allData);
                console.log(res.data.allData, '$$$GG');
            } else {
                console.log(res.data.validation_error);
            }
        });
    }

    return (
        <>
            <div className={"col-md-"+size}>
                <select className="form-select" id="productCat" value={props.productCat} style={{ height: "55px" }} onChange={(event) => {
                    props.setProductCat(event.target.value);
                }} placeholder={titles}>
                    <option selected>Select Product Category</option>
                    {allCatigories.map((allCatigorie, index) => {

                        return <>
                            <option value={allCatigorie.productCategory}>{allCatigorie.productCategory}</option>
                        </>;
                    })}

                </select>
            </div>
        </>
    )
}

export default ProductCat
