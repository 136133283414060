import React, { useEffect, useState } from 'react'
const LastName = (props) => {

    const [titles, setTitles] = useState('');
    const [size, setSize] = useState(6);
    useEffect(() => {
        
        setTitles(props.loginTitle);
        setSize(props.size);
    }, [props.loginTitle, props.size]);

    return (
        <>
            <div className={"col-md-"+size}>
                <div className="form-floating">
                    <input type="text" disabled={props.inputState} className="form-control" id="lastName" placeholder="Your Last Name" value={props.lastName}
                        onChange={(event) => {
                            props.setLastName(event.target.value);
                        }}
                    />
                    <label for="lastName">{titles}</label>
                </div>
            </div>
        </>
    )
}

export default LastName
