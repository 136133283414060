import React from "react";
import { Routes, Route } from "react-router-dom";
import MasterFrontwebpage from "./layouts/frontend/MasterFrontwebpage";
import axios from "axios";
import AdminPrivateRoutes from "./AdminPrivateRoutes";
//import axios from "axios";
//import "bootstrap/dist/css/bootstrap.min.css";

/*
 Used for post man: http://127.0.0.1:3000/
//This would make sure that all transactions are in json formate (note good for file upload)
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.post["Accept"] = "application/json";

//This would request for authorization before accessing the application. Good for API interface
axios.defaults.headers.common = {
  //Authorization: "Bearer 1|M6YQDupH3ILtPUG1Rg9T8qdgi0rDLJVoZe1ijo4eeed986d4",
  "Access-Control-Allow-Origin": "https://admin.savvypremier.ng", //http://127.0.0.1:3000 or *
  "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept, Authorization",
  "Access-Control-Allow-Methods": "GET, POST, OPTIONS, PUT, PATCH, DELETE",
  "Access-Control-Allow-Credentials": "true",
};
*/

//let defaultsBend = "http://127.0.0.1:8000/";
let defaultsBend = "https://bend.savvypremier.ng/";

axios.defaults.baseURL = defaultsBend;
axios.defaults.withXSRFToken = true;
axios.defaults.withCredentials = true;
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

axios.interceptors.request.use(function (config) {
    const token = localStorage.getItem("auth_token");
    config.headers.Authorization = token
        ? `Bearer ${token}`
        : "";
    return config;
});
function App() {

    return (
        <>
            <Routes>


                {/* Frontend Routing */}

                {/* Admin Login Section */}
                <Route path="/login" element={<MasterFrontwebpage name="Login" catigory="Login" />} />
                <Route path="/logout" element={<MasterFrontwebpage name="Login" catigory="Login" />} />
                <Route
                    path="/register01"
                    element={<MasterFrontwebpage name="Register01" catigory="Registration" />}
                />
                <Route
                    path="/register02"
                    element={<MasterFrontwebpage name="Register02" catigory="Create An Account" />}
                />
                <Route
                    path="/register02/:otp"
                    element={<MasterFrontwebpage name="Register02b" catigory="Create An Account" />}
                />
                <Route
                    path="/forgotpassword01"
                    element={<MasterFrontwebpage name="Forgotpassword01" catigory="Forgot Password" />}
                />
                <Route
                    path="/forgotpassword02"
                    element={<MasterFrontwebpage name="Forgotpassword02" catigory="Forgot Password" />}
                />
                <Route
                    path="/forgotpassword02/:otp"
                    element={<MasterFrontwebpage name="Forgotpassword02b" catigory="Forgot Password" />}
                />
                {/* End of Admin Login Section */}


                <Route path="/" element={<MasterFrontwebpage name="HomePage" catigory="Home Page" />} />
                
                <Route path="/privacypolicy" element={<MasterFrontwebpage name="PrivacyPolicy" catigory="Privacy Policy" />} />
                <Route path="/disclaimer" element={<MasterFrontwebpage name="Disclaimer" catigory="Disclaimer" />} />
                <Route path="/termsofuse" element={<MasterFrontwebpage name="TermsofUse" catigory="Terms of Use" />} />

                <Route path="/aboutus" element={<MasterFrontwebpage name="AboutUs" catigory="About Us" />} />
                <Route path="/contact" element={<MasterFrontwebpage name="Contacts" catigory="Contact" />} />
                <Route path="/faq" element={<MasterFrontwebpage name="Faq" catigory="Faq" />} />
                <Route path="/team" element={<MasterFrontwebpage name="Teams" catigory="Our Team" />} />
                <Route path="/product" element={<MasterFrontwebpage name="Products" catigory="Product" />} />
                <Route path="/cart/:cartid" element={<MasterFrontwebpage name="Carts" catigory="Shopping Cart" />} />
                <Route path="/readmore/:otp" element={<MasterFrontwebpage name="ReadMore" catigory="Read More" />} />
                <Route path="/itemdetail/:itemid" element={<MasterFrontwebpage name="ItemDetails" catigory="Item Details" />} />
                <Route path="/teamdetail/:teamid" element={<MasterFrontwebpage name="TeamDetails" catigory="Team Details" />} />
                <Route path="/checkout/:checkoutid" element={<MasterFrontwebpage name="Checkouts" catigory="Checkout" />} />
                <Route path="/quote" element={<MasterFrontwebpage name="Quotes" catigory="Quote" />} />

                <Route path="/404" element={<MasterFrontwebpage name="Page404" catigory="404 Error" />} />

                <Route path="/paystack/calback" element={<MasterFrontwebpage name="PaystackVerifys" catigory="Processing Payment" />} />

                <Route path="/item/:itemdetails" element={<MasterFrontwebpage name="ItemsPurchaseDetails" catigory="Purchase Items Details" />} /> {/* check you item in an order */}

                <Route path="/orders/:orderdetails" element={<MasterFrontwebpage name="OrderDetails" catigory="Order Details" />} /> {/* shows all the orders */}

                <Route path="/*" element={<MasterFrontwebpage name="Page404" catigory="404 Error" />} />
                
                {/* End of Frontend Routing */}

                {/* For Logins */}
                <Route element={<AdminPrivateRoutes />}>

                    <Route path="/dashboard" element={<MasterFrontwebpage name="Dashboards" catigory="My Account" />} />

                </Route>
                {/* End of For Logins */}
            </Routes>
        </>
    );



}

export default App;
