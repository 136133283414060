import React from "react";
import ReadMoreText from "./childComponents/pageLayouts/ReadMoreText";

const ReadMore = () => {

    
    return (
        <>
            <ReadMoreText  />

        </>
    )
}

export default ReadMore
