import HomePage from "../../components/frontend/HomePage";
import Disclaimer from "../../components/frontend/Disclaimer";
import PrivacyPolicy from "../../components/frontend/PrivacyPolicy";
import TermsofUse from "../../components/frontend/TermsofUse";
import Faq from "../../components/frontend/Faq";
import Quotes from "../../components/frontend/Quotes";
import AboutUs from "../../components/frontend/AboutUs";
import Products from "../../components/frontend/Products";
import Teams from "../../components/frontend/Teams";
import Contacts from "../../components/frontend/Contacts";
import ReadMore from "../../components/frontend/ReadMore";
import Carts from "../../components/frontend/Carts";
import Checkouts from "../../components/frontend/Checkouts";
import ItemDetails from "../../components/frontend/ItemDetails";
import TeamDetails from "../../components/frontend/TeamDetails";

//import ItemsPurchaseDetails from "../../components/frontend/ItemsPurchaseDetails";
import PaystackVerify from "../../components/frontend/childComponents/appComponents/PaystackVerify";
import Dashboards from "../../components/frontend/Dashboards";

import Login from "../../components/authFrontend/Login";
import Forgotpassword01 from "../../components/authFrontend/Forgotpassword01";
import Forgotpassword02 from "../../components/authFrontend/Forgotpassword02";
import Forgotpassword02b from "../../components/authFrontend/Forgotpassword02b";
import Register01 from "../../components/authFrontend/Register01";
import Register02 from "../../components/authFrontend/Register02";
import Register02b from "../../components/authFrontend/Register02b";


import Page404 from "../../components/frontend/Page404";

import Footer from './Footer';
import Navbar from './Navbar';
import Slider from "./Slider";
import StaticSlider from "./StaticSlider";

const MasterFrontwebpage = (props) => {



    let tagName, tagSliderName;

    switch (props.name) {
        case "HomePage":
            tagName = <><HomePage catigoryChild={props.catigory} /></>;
            break;
        case "Disclaimer":
            tagName = <Disclaimer catigoryChild={props.catigory} />;
            break;
        case "PrivacyPolicy":
            tagName = <PrivacyPolicy catigoryChild={props.catigory} />;
            break;
        case "TermsofUse":
            tagName = <TermsofUse catigoryChild={props.catigory} />;
            break;
        case "Login":
            tagName = <><Login catigoryChild={props.catigory} /></>;
            break;

        case "Forgotpassword01":
            tagName = <><Forgotpassword01 catigoryChild={props.catigory} /></>;
            break;
        case "Forgotpassword02":
            tagName = <><Forgotpassword02 catigoryChild={props.catigory} /></>;
            break;
        case "Forgotpassword02b":
            tagName = <><Forgotpassword02b catigoryChild={props.catigory} /></>;
            break;
        case "Register01":
            tagName = <><Register01 catigoryChild={props.catigory} /></>;
            break;
        case "Register02":
            tagName = <><Register02 catigoryChild={props.catigory} /></>;
            break;
        case "Register02b":
            tagName = <><Register02b catigoryChild={props.catigory} /></>;
            break;
        case "Contacts":
            tagName = <Contacts catigoryChild={props.catigory} />;
            break;
        case "Faq":
            tagName = <Faq catigoryChild={props.catigory} />;
            break;
        case "AboutUs":
            tagName = <AboutUs catigoryChild={props.catigory} />;
            break;

        case "Products":
            tagName = <Products catigoryChild={props.catigory} />;
            break;
        case "Teams":
            tagName = <Teams catigoryChild={props.catigory} />;
            break;
        case "TeamDetails":
            tagName = <TeamDetails catigoryChild={props.catigory} />;
            break;

        case "Quotes":
            tagName = <Quotes />;
            break;
        case "Carts":
            tagName = <Carts />;
            break;
        case "Checkouts":
            tagName = <Checkouts />;
            break;
        case "ItemDetails":
            tagName = <ItemDetails />;
            break;


        case "PaystackVerifys":
            tagName = <PaystackVerify />;
            break;
        case "ReadMore":
            tagName = <ReadMore />;
            break;
        case "Page404":
            tagName = <Page404 />;
            break;

        case "Dashboards":
            tagName = <Dashboards catigoryChild={props.catigory} names={props.name} catigorys={props.catigory} />;
            break;
        case "OrderDetails":
            tagName = <Dashboards catigoryChild={props.catigory} names={props.name} catigorys={props.catigory} />;
            break;
        case "ItemsPurchaseDetails":
            tagName = <Dashboards catigoryChild={props.catigory} names={props.name} catigorys={props.catigory} />;
            break;
        default:
            tagName = <HomePage />;


    }

    tagSliderName = <Slider />;

    if (props.name !== "HomePage") {
        tagSliderName = <StaticSlider catigoryChild={props.catigory} />;
    }
    // if (loading) {
    // Loading..., Processing Payment, Please Dont Interrupt
    //return <Loading message01={'Loading...'} />;
    //   }
    return (
        <>
            <Navbar />
            {tagSliderName}
            <div className="container-flex bg-light overflow-hidden my-5 px-lg-0" style={{ minHeight: "400px" }}>
                <div className="container-flex px-lg-0">

                    <div className="row g-0 mx-lg-0">
                        {tagName}

                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
}

export default MasterFrontwebpage
