import React from "react";

import TestimonialSlide from "./TestimonialSlide";
const Testimonial = (props) => {

    return (
        <>
            <div className="testimonials">
                <div id="myCarousel" className="carousel slide " data-ride="carousel">
                    {/* <!-- Carousel indicators --> */}
                    <ol className="carousel-indicators">
                        <li data-target="#myCarousel" data-slide-to="0" className="active"></li>
                        <li data-target="#myCarousel" data-slide-to="1"></li>
                        <li data-target="#myCarousel" data-slide-to="2"></li>
                    </ol>
                    {/* <!-- Wrapper for carousel items --> */}
                    <div className="carousel-inner">
                        <TestimonialSlide />
                    </div>
                    {/* <!-- Carousel controls --> */}
                    <a className="carousel-control-prev" href="#myCarousel" data-slide="prev">
                        <i className="fa fa-angle-left"></i>
                    </a>
                    <a className="carousel-control-next" href="#myCarousel" data-slide="next">
                        <i className="fa fa-angle-right"></i>
                    </a>
                </div>
            </div>
        </>
    )
}

export default Testimonial
