import React, { useEffect, useState } from 'react'
const Message = (props) => {

    
    const [titles, setTitles] = useState('');
    const [size, setSize] = useState(12);
    useEffect(() => {
    
        setTitles(props.loginTitle);
        setSize(props.size);
    }, [props.loginTitle, props.size]);

  


    return (
        <>
            <div className={"col-md-"+size}>
                <div className="form-floating">
                    <textarea className="form-control" disabled={props.inputState} placeholder="Leave a Note about your order. Special Notes on delivery" id="message" style={{ height: '100px' }} value={props.message}
                        onChange={(event) => {
                            props.setMessage(event.target.value);
                        }}
                    ></textarea>
                    <label for="message">{titles}</label>
                </div>
            </div>
        </>
    )
}

export default Message
