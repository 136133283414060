import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
const LoginLink = (props) => {

    
    const [titles, setTitles] = useState('');
    const [size, setSize] = useState(6);
    useEffect(() => {
        
        setTitles(props.loginTitle);
        setSize(props.size);
    }, [props.loginTitle, props.size]);


    return (
        <>
            <div className={"col-md-"+size}>
                <div className="form-floating float-end">
                    <Link to='/login'>
                        <h6 for="loginLinks">{titles}</h6>
                    </Link>
                </div>
            </div>
        </>
    )
}

export default LoginLink
