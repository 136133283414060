import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import Loading from "../appComponents/Loading";
import { HiOutlineDocumentSearch } from "react-icons/hi";
import { GiReceiveMoney } from "react-icons/gi";
import { FaShippingFast } from "react-icons/fa";
import { LuShip } from "react-icons/lu";
const Dashboard = () => {

    //itemdetails, orderdetails

    const [loading, setLoading] = useState(true);
    const [browserToken, setBrowserToken] = useState(localStorage.getItem("user_id"));
    const [cartRecords, setCartRecords] = useState([]); //store defualt search records
    const [imageBends, setImageBends] = useState();
    const [buttonControl, setButtonControl] = useState(false);

    const [allItemsRecords, setAllItemsRecords] = useState([]);
    const [pendingRecords, setPendingRecords] = useState([]);
    const [paidRecords, setPaidRecords] = useState([]);
    const [shippedRecords, setShippedRecords] = useState([]);
    const [messages, setMessages] = useState();

    useEffect(() => {
        (async () => await tableLoad())();
    }, []);

    const searchDatabase = (event) => {
        event.preventDefault();
        let text = event.currentTarget.dataset.value;

        tableLoad(text);
    }

    async function tableLoad(text) {
        let datas = "?cartUsersId[eq]=" + browserToken;
        //let messages = '';

        if (text === 'All Items') {
            //datas = datas;
            setMessages('ALL ITEMS');
            setButtonControl(false);
        } else if (text === 'Pending') {
            datas = datas + `&status[eq]=pending&paymentStatus[eq]=Unpaid`;
            setButtonControl(true);
           setMessages('ALL ITEMS WITH PENDING PURCHASE');

        } else if (text === 'Paid') {
            datas = datas + `&status[eq]=pending&paymentStatus[eq]=Paid`;
            setButtonControl(false);
            setMessages('ALL ITEMS TO SHIP');

        } else if (text === 'Shipped') {
            datas = datas + `&status[eq]=shipped&paymentStatus[eq]=Paid`;
            setButtonControl(false);
            setMessages('ALL ITEMS SHIPPED');
        }

        await axios.get("api/user/v1/cart_page" + datas).then((res) => {
            // Gets the address of the backend server to be able to point the Images currectly
            if (res.data.status === 200) {
                //localStorage.setItem("bend", res.data.bend);

                setCartRecords(res.data.allData);
                console.log(res.data.bend);
            } else if (res.data.status === 401) {
                swal("Oop!", res.data.message, "warning");
            } else if (res.data.status === 500) {
                swal("Oop!", res.data.message, "warning");
            }
            else {
                swal("Warning", res.data.message, "warning");
                console.log(res.data.message);
                console.log(res.data.validation_error);
            }
        });


        axios.get("api/user/v1/general").then((res) => {
            // Gets the address of the backend server to be able to point the Images currectly
            if (res.data.status === 200) {
                //localStorage.setItem("bend", res.data.bend);
                setImageBends(res.data.bend);
                setLoading(false);
                console.log(res.data.bend);
            } else if (res.data.status === 401) {
                swal("Oop!", res.data.message, "warning");

            } else if (res.data.status === 500) {
                swal("Oop!", res.data.message, "warning");

            }
            else {
                swal("Warning", res.data.message, "warning");
                console.log(res.data.message);
                console.log(res.data.validation_error);

            }
        });

        let datass = "?cartUsersId[eq]=" + browserToken;
        await axios.get("api/user/v1/cart_quantityproducts" + datass).then((res) => {
            // Gets the address of the backend server to be able to point the Images currectly
            if (res.data.status === 200) {
                //localStorage.setItem("bend", res.data.bend);

                setAllItemsRecords(res.data.allItems);
                setPendingRecords(res.data.pending);
                setPaidRecords(res.data.paid);
                setShippedRecords(res.data.shipped);

                //console.log(browserToken, '$$$444');
            } else {
                console.log(res.data.validation_error);
            }
        });

    }

    async function deleteItems(event) {
        event.preventDefault();
        let itemId = event.currentTarget.dataset.value;

        await axios.get("sanctum/csrf-cookie").then((response) => {
            try {
                axios.delete("api/user/v1/cart_page/" + itemId).then((res) => {
                    if (res.data.status === 200) {
                        console.log(res.data.message);
                        /*
                        localStorage.setItem('auth_token', res.data.token);
                        localStorage.setItem('auth_name', res.data.firstName);
                        */
                        swal("Success", res.data.message, "success");
                        tableLoad();
                        //navigate('/'); //redirect to the home page

                    } else {
                        swal("Failed", res.data.message, "warning");
                        // console.log(res.data.message);
                        // console.log(res.data.validation_error);
                    }
                });
            } catch (error) {
                swal("Oops!", "Item was not deleted from the Cart", "error");
                console.log(error.response.data);
            }
        });

    }
    if (loading) {
        // Loading, Processing Payment, Please Dont Interrupt
        return <Loading message01={'Processing'} />;
    }
    let totalcost = 0;
    return (
        <>

            {cartRecords.length > 0 ?
                <>
                    <div className="col-lg-9 p-lg px-3" style={{ minHeight: "400px" }}>
                        <div className="m-3">{messages}</div>
                        <div className="col-12">
                            <div className="row m-3 border">
                                <div className="col-3 border-end p-3"><h6><Link to='' onClick={searchDatabase} data-value='All Items'><HiOutlineDocumentSearch style={{fontSize:'28px'}}/> View All ({allItemsRecords.length})</Link></h6></div>
                                <div className="col-3 border-end p-3"><h6><Link to='' onClick={searchDatabase} data-value='Pending'><GiReceiveMoney style={{fontSize:'28px'}}/> To Pay ({pendingRecords.length})</Link></h6></div>
                                <div className="col-3 border-end p-3"><h6><Link to='' onClick={searchDatabase} data-value='Paid'><FaShippingFast style={{fontSize:'28px'}}/> To Ship ({paidRecords.length})</Link></h6></div>
                                <div className="col-3 p-3"><h6><Link to='' onClick={searchDatabase} data-value='Shipped'><LuShip style={{fontSize:'28px'}}/> Shipped ({shippedRecords.length})</Link></h6></div>
                            </div>
                        </div>

                        {cartRecords.map((cartRecord, index) => {
                            function numberWithCommas(x) {
                                if (!x) { x = 0; }
                                return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                            }
                            // setTotalcost(totalcost + cartRecord.totalCost);

                            const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

                            let d = new Date(cartRecord.created_at);
                            let month = months[d.getMonth()];
                            let day = d.getDate();
                            let year = d.getFullYear();
                            let completDate = day + ' ' + month + ', ' + year;

                            totalcost = totalcost + cartRecord.totalCost;
                            let paymentStatusIs;
                            if (cartRecord.status === 'pending') {
                                paymentStatusIs = 'Processing';
                            } else if (cartRecord.status === 'shipped') {
                                paymentStatusIs = 'Complete';
                            } else if (cartRecord.status === 'checked') {
                                paymentStatusIs = 'Pending Payment';
                            } else {
                                paymentStatusIs = 'Processing';
                            }

                            return <>
                                <div className="border-bottom p-2 mb-2">
                                    <div className="col-12 m-2" style={{ fontSize: "14px" }}>
                                        <div className="row">
                                            <div className="col-5"><h6>{paymentStatusIs}</h6></div>
                                            <div className="col-4 text-end">
                                                Order Date: {completDate}<br />
                                                Order ID: {cartRecord.referenceNo}
                                            </div>
                                            <div className="col-3 pe-4 text-end text-bold"><h6>Order Details</h6></div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-2 mb-2">
                                            <div style={{ fontSize: "14px" }} className="mb-2 text-center">
                                                {cartRecord.productCatigory}
                                            </div>
                                            <div>
                                                <Link to={"/itemdetail/" + cartRecord.itemId}><img className="img-fluid w-40" src={imageBends + cartRecord.uploadFile} style={{ objectFit: "cover" }} alt="" />
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="col-7">
                                            <Link to={"/itemdetail/" + cartRecord.itemId}>
                                                <div className="col-12">
                                                    <h6>{cartRecord.itemName}</h6>
                                                </div>

                                                <div className="col-12 pt-5">
                                                    Quantity: {cartRecord.quantity}
                                                </div>
                                                <div className="col-12">
                                                    Unit Cost: ₦{numberWithCommas(cartRecord.unitCost)}
                                                </div>
                                            </Link>

                                        </div>
                                        <div className="col-3 mb-2">
                                            <div className="text-center mt-4 ">
                                                <h6>₦{numberWithCommas(cartRecord.totalCost)}</h6>
                                            </div>
                                            <Link to={'/itemdetail/' + cartRecord.itemId} className="col-12 mt-2 btn btn-primary"><i className="fa fa-shopping-cart me-1"></i>Add to Cart</Link>
                                            {buttonControl && <button className="btn btn-danger col-12 mt-2" data-wow-delay="0.5s" id="delete" data-value={cartRecord.id} onClick={deleteItems}><i className="fa fa-trash"></i> Delete </button>}

                                        </div>
                                    </div>
                                </div>
                            </>
                        })}
                    </div>
                </> :
                <>

                    <div className="col-lg-9 p-lg px-3" style={{ minHeight: "400px" }}>
                    <div className="m-3">{messages}</div>
                        <div className="col-12">
                            <div className="row m-3 border">
                                <div className="col-3 border-end p-3"><h6><Link to='' onClick={searchDatabase} data-value='All Items'><HiOutlineDocumentSearch style={{fontSize:'28px'}}/> View All ({allItemsRecords.length})</Link></h6></div>
                                <div className="col-3 border-end p-3"><h6><Link to='' onClick={searchDatabase} data-value='Pending'><GiReceiveMoney style={{fontSize:'28px'}}/> To Pay ({pendingRecords.length})</Link></h6></div>
                                <div className="col-3 border-end p-3"><h6><Link to='' onClick={searchDatabase} data-value='Paid'><FaShippingFast style={{fontSize:'28px'}}/> To Ship ({paidRecords.length})</Link></h6></div>
                                <div className="col-3 p-3"><h6><Link to='' onClick={searchDatabase} data-value='Shipped'><LuShip style={{fontSize:'28px'}}/> Shipped ({shippedRecords.length})</Link></h6></div>
                            </div>
                        </div>

                        <div className="text-center align-item-center pt-5">
                            <h3 className="align-item-center">EMPTY! CART</h3>
                            <i className="fa fa-shopping-cart p-3" style={{ fontSize: '15em' }}></i>
                            <Link to='/product'> <h5 className="btn btn-primary align-item-center">Return To Shopping</h5></Link>
                        </div>
                    </div>
                </>
            }
        </>
    )
}

export default Dashboard
