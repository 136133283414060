import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSearchParams, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import Loading from "./Loading";
const PaystackVerify = () => {

  const navigate = useNavigate("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    verifyPayment();

  }, []);

  const verifyPayment = async () => {
    let references;
    if (searchParams.has('reference')) {
      references = searchParams.get('reference');
    }

    const VeryfyProductPayment = {
      reference: references,
      referenceNo: localStorage.getItem("referenceNo"), // this is my own generated refrence Number

    };

    await axios.get("sanctum/csrf-cookie").then((response) => {
      try {
        axios.post("api/user/v1/verify_purchase", VeryfyProductPayment).then((res) => {
          if (res.data.status === 200) {

            //let datas = JSON.parse(res.data.message);//convert Json to an Object
            console.log(res.data.verificationResult, '$$$Testdd');

            swal("Payment Successfull", res.data.message, "success");
            setLoading(false);
            navigate("/product"); //redirect to the Home Page

          } else if (res.data.status === 401) {
            swal("Oop!", res.data.message, "warning");
            setLoading(false);
            localStorage.removeItem('totalcost');
            localStorage.removeItem('payAmount');
            navigate("/product"); //redirect to the Home Page
          } else if (res.data.status === 500) {
            swal("Oop!", res.data.message, "warning");
            setLoading(false);
            navigate("/product"); //redirect to the Home Page
          }
          else {
            swal("Warning", res.data.message, "warning");
            setLoading(false);
            navigate("/product"); //redirect to the Home Page
            console.log(res.data.message);
            console.log(res.data.validation_error);

          }
        });
      } catch (error) {
        swal("Oops!", "Failed", "error");
      }
    });
  }
  if (loading) {
    // Loading..., Processing Payment, Please Dont Interrupt
    return <Loading message01={'Processing Payment'} message02={'Please Dont Interrupt'} />;
  }

  return (
    <div className="container bg-light overflow-hidden my-5 px-lg-0">
      <div className="container about px-lg-0">
        <div className="row g-0 mx-lg-0">

          <div className="col-lg-12 p-lg-3 text-center align-item-center" style={{ minHeight: "400px" }}>
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaystackVerify;