import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import swal from "sweetalert";
const ReadMoreText = () => {

    const { otp } = useParams();
    const [otpToken, setOtptoken] = useState({ otp }.otp);

    //console.log(otpToken, '$$$449');
    const [readmeRecords, setReadmeRecords] = useState([]); //store defualt search records
    const [imageBends, setImageBends] = useState();
    useEffect(() => {
        (async () => await tableLoad())();
    }, []);

    async function tableLoad() {
        let datas;

        datas = "?id[eq]=" + otpToken + "&activeStatus[eq]=Active";

        await axios.get("api/user/v1/post_page" + datas).then((res) => {
            // Gets the address of the backend server to be able to point the Images currectly
            if (res.data.status === 200) {
                //localStorage.setItem("bend", res.data.bend);
                
                setReadmeRecords(res.data.allData);
                console.log(res.data.bend);
            } else if (res.data.status === 401) {
                swal("Oop!", res.data.message, "warning");

            } else if (res.data.status === 500) {
                swal("Oop!", res.data.message, "warning");

            }
            else {
                swal("Warning", res.data.message, "warning");
                console.log(res.data.message);
                console.log(res.data.validation_error);

            }
        });
        

        axios.get("api/user/v1/general").then((res) => {// Gets the address of the backend server to be able to point the Images currectly
            if (res.data.status === 200) {
                //localStorage.setItem("bend", res.data.bend);
                setImageBends(res.data.bend);
                console.log(res.data.bend);
            } else if (res.data.status === 401) {
                swal("Oop!", res.data.message, "warning");

            } else if (res.data.status === 500) {
                swal("Oop!", res.data.message, "warning");

            }
            else {
                swal("Warning", res.data.message, "warning");
                console.log(res.data.message);
                console.log(res.data.validation_error);

            }
        });



    }
    /* End of Defualt Search Records */

    // function removeTags(str) {
    //     if ((str === null) || (str === ''))
    //         return false;
    //     else
    //         str = str.toString();
    //     return str.replace(/(<([^>]+)>)/ig, '');
    // }



    return (
        <>
            {/* ReadMoreText  */}

            <div className="container-fluid bg-light overflow-hidden my-5 px-lg-0">
                <div className="container about px-lg-0">
                    <div className="row g-0 mx-lg-0">
                        {readmeRecords.map((readmeRecord, index) => {
                            return <>
                                <div className="col-lg-6 ps-lg-0" style={{ minHeight: "400px" }}>
                                    <div className="position-relative h-100">
                                        <img className="position-absolute img-fluid w-100" src={imageBends + readmeRecord.uploadFile} style={{ objectFit: "cover" }} alt="" />
                                    </div>
                                </div>
                                <div className="col-lg-6 about-text py-5 wow fadeIn" data-wow-delay="0.5s">
                                    <div className="p-lg-5 pe-lg-0">
                                        <div className="section-title text-">
                                            <h1 className="display-5 mb-4">{readmeRecord.pageTitle}</h1>
                                        </div>
                                        <p className="mb-4 pb-2 text-justify">

                                            <div dangerouslySetInnerHTML={{ __html: readmeRecord.message }} />

                                        </p>
                                    </div>
                                </div></>
                        })}

                    </div>
                </div>
            </div>
            {/* ReadMoreText End */}
        </>
    )
}

export default ReadMoreText
