import React from 'react'
const Loading = (props) => {

    return (
        <>
            
                <div id="loader"></div>
                <div id="loadertest" className="text-center text-primary">
                    <h3 className="text-center text-primary">{props.message01}</h3>
                <h5 className="text-center text-primary">{props.message02}</h5>
            </div>

        </>
    )
}

export default Loading
