import React, { useEffect, useState } from 'react'
const Email = (props) => {
    
    const [titles, setTitles] = useState('');
    const [size, setSize] = useState(12);
    useEffect(() => {
        setTitles(props.quoteTitle);
        setSize(props.size);
    }, [props.quoteTitle, props.size]);


    return (
        <>
            <div className={"col-md-"+size}>
                <input type="email" className="form-control" id="email" value={props.email} placeholder={titles} style={{ height: "55px" }} onChange={(event) => {
                    props.setEmail(event.target.value);
                }} />
            </div>
        </>
    )
}

export default Email
