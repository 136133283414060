import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import CAPTCHATurnStile from "../frontend/childComponents/appComponents/CAPTCHATurnStile";
import Username from "../frontend/childComponents/inputComponents/Checkouts/Username";
import RegistrationLink from "../frontend/childComponents/inputComponents/Checkouts/RegistrationLink";
import LoginLink from "../frontend/childComponents/inputComponents/Checkouts/LoginLink";
import { RiLockPasswordLine } from "react-icons/ri";
const buttonName = ' Reset Password';
const Forgotpassword01 = () => {
  const navigate = useNavigate("");

  const [loginEmail, setLoginEmail] = useState();
  const [reCaptcha, setReCaptcha] = useState(false);
  const [loading, setLoading] = useState(buttonName); //to show loading process
  const [registrationLink, setRegistrationLink] = useState();
  const [loginLink, setLoginLink] = useState();

  async function resetPassword(event) {
    event.preventDefault();
    // setLoading(<div><i className="fa fa-refresh fa-spin"></i></div>);
    setLoading(<div>Loading...</div>);
    const datas = {
      email: loginEmail,
    };

    await axios.get("sanctum/csrf-cookie").then((response) => {
      try {
        axios
          .post("api/user/v1/forgot_passwords/validate", datas)
          .then((res) => {
            if (res.data.status === 200) {
              console.log(res.data.message);

              localStorage.setItem("auth_email", res.data.authEmail);
              localStorage.setItem("auth_phone", res.data.authPhone);
              localStorage.setItem("firstName", res.data.firstName);
              localStorage.setItem("lastName", res.data.lastName);
              /**/
              swal("Success", res.data.message, "success");
              setLoading(buttonName);
              navigate("/forgotpassword02"); //redirect to the home page
            } else if (res.data.status === 501) {
              swal("Caution", res.data.message, "warning");
              navigate("/forgotpassword02"); //redirect to the home page
              setLoading(buttonName);
            } else {
              swal("Warning", res.data.message, "warning");
              console.log(res.data.message);
              console.log(res.data.validation_error);
              setLoading(buttonName);
            }
          });
      } catch (error) {
        swal("Oops!", "Validation Failed", "error");
        console.log(error.response.data);
        setLoading(buttonName);
      }
    });
  }
  return (

    <div className="container-fluid bg-light overflow-hidden my-5 px-lg-0">
      <div className="container quote px-lg-0">
        <div className="row g-0 mx-lg-0">
          <div className="col-lg-6 ps-lg-0" style={{ minHeight: "400px" }}>
            <div className="position-relative h-100">
              <img className="position-absolute img-fluid w-100" src="/assets/img/cybersecurity-mesh.jpg" style={{ objectFit: "cover" }} alt="" />
            </div>
          </div>
          <div className="col-lg-6 quote-text py-5 wow fadeIn" data-wow-delay="0.5s">
            <div className="p-lg-5 pe-lg-0">
              <h6 className='p-3'>Note: Enter your email address below and we'll send you password reset
                instructions.</h6>
              <form>
                <div className="row g-3">
                  <Username loginEmail={loginEmail} size='12' loginTitle='Email *' setLoginEmail={setLoginEmail} />

                  <CAPTCHATurnStile reCaptcha={reCaptcha} setReCaptcha={setReCaptcha} />

                  <div className="col-md-12 mb-2">
                    <button
                      disabled={!reCaptcha} className="btn btn-primary col-md-12" onClick={resetPassword}
                    ><RiLockPasswordLine style={{ fontSize: '20px' }} />
                      {loading}
                    </button>
                  </div>
                  <RegistrationLink size='6' registrationLink={registrationLink} loginTitle='Need an account? Sign up!' setRegistrationLink={setRegistrationLink} />

                  <LoginLink loginLink={loginLink} size='6' loginTitle='Return to Login' setLoginLink={setLoginLink} />

                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Forgotpassword01;
