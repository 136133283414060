import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
const ForgottenPasswordLink = (props) => {

    const [titles, setTitles] = useState('');
    const [floats, setFloats] = useState('float-start');
    const [size, setSize] = useState(6);
    useEffect(() => {
        
        setTitles(props.loginTitle);
        setFloats(props.float);
        setSize(props.size);
    }, [props.loginTitle, props.float, props.size]);

    return (
        <>
            <div className={"col-md-"+size}>
                <div className={"form-floating " + floats}>
                    <Link to='/forgotpassword01'>
                        <h6 for="forgottenPasswordLinks">{titles}</h6>
                    </Link>
                </div>
            </div>
        </>
    )
}

export default ForgottenPasswordLink
