import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import Loading from "../appComponents/Loading";
const Cart = () => {

    const [browserToken, setBrowserToken] = useState(localStorage.getItem("browserToken"));
    const [loading, setLoading] = useState(true);

    const [cartRecords, setCartRecords] = useState([]); //store defualt search records
    const [imageBends, setImageBends] = useState();

    useEffect(() => {
        (async () => await tableLoad())();
    }, []);

    async function tableLoad() {
        let datas;
        datas = "?browserToken[eq]=" + browserToken + "&status[eq]=checked";
        if (localStorage.getItem("user_id")) {
            datas = "?cartUsersId[eq]=" + localStorage.getItem("user_id") + "&status[eq]=checked";
        }

        await axios.get("api/user/v1/cart_page" + datas).then((res) => {
            // Gets the address of the backend server to be able to point the Images currectly
            if (res.data.status === 200) {
                //localStorage.setItem("bend", res.data.bend);

                setCartRecords(res.data.allData);
                console.log(res.data.bend);
            } else {
                console.log(res.data.validation_error);
            }
        });


        axios.get("api/user/v1/general").then((res) => {
            // Gets the address of the backend server to be able to point the Images currectly
            if (res.data.status === 200) {
                //localStorage.setItem("bend", res.data.bend);
                setImageBends(res.data.bend);
                setLoading(false);
                console.log(res.data.bend);
            } else {
                console.log(res.data.validation_error);
            }
        });

    }
    async function deleteItems(event) {
        event.preventDefault();
        let itemId = event.currentTarget.dataset.value;

        await axios.get("sanctum/csrf-cookie").then((response) => {
            try {
                axios.delete("api/user/v1/cart_page/" + itemId).then((res) => {
                    if (res.data.status === 200) {
                        console.log(res.data.message);
                        /*
                        localStorage.setItem('auth_token', res.data.token);
                        localStorage.setItem('auth_name', res.data.firstName);
                        */
                        swal("Success", res.data.message, "success");
                        tableLoad();
                        //navigate('/'); //redirect to the home page

                    } else {
                        swal("Failed", res.data.message, "warning");
                        // console.log(res.data.message);
                        // console.log(res.data.validation_error);
                    }
                });
            } catch (error) {
                swal("Oops!", "Item was not deleted from the Cart", "error");
                console.log(error.response.data);
            }
        });

    }

    function numberWithCommas(x) {
        if (!x) { x = 0; }
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    if (loading) {
        // Loading, Processing Payment, Please Dont Interrupt
        return <Loading message01={'Processing'} />;
    }
    let totalcost = 0;
    return (
        <>
            {/* Dashboards  */}
            <div className="container bg-light overflow-hidden my-5 px-lg-0">
                <div className="container px-lg-0">

                    <div className="row g-0 mx-lg-0">
                        <>
                            {cartRecords.length > 0 ?
                                <>
                                    <div className="col-lg-9 p-lg-3" style={{ minHeight: "400px" }}>
                                        {cartRecords.map((cartRecord, index) => {
                                            function numberWithCommas(x) {
                                                if (!x) { x = 0; }
                                                return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                                            }
                                            // setTotalcost(totalcost + cartRecord.totalCost);

                                            const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

                                            let d = new Date(cartRecord.created_at);
                                            let month = months[d.getMonth()];
                                            let day = d.getDate();
                                            let year = d.getFullYear();
                                            let completDate = day + ' ' + month + ', ' + year;

                                            totalcost = totalcost + cartRecord.totalCost;
                                            let paymentStatusIs;
                                            if (cartRecord.status === 'pending') {
                                                paymentStatusIs = 'Processing';
                                            } else if (cartRecord.status === 'shipped') {
                                                paymentStatusIs = 'Complete';
                                            } else if (cartRecord.status === 'checked') {
                                                paymentStatusIs = 'Pending Payment';
                                            } else {
                                                paymentStatusIs = 'Processing';
                                            }

                                            return <>
                                                <div className="border-bottom p-2 mb-2">
                                                    <div className="col-12 m-2" style={{ fontSize: "14px" }}>
                                                        <div className="row">
                                                            <div className="col-5"><h6>{paymentStatusIs}</h6></div>
                                                            <div className="col-4 text-end">
                                                                Order Date: {completDate}<br />
                                                                Order ID: {browserToken}
                                                            </div>
                                                            <div className="col-3 pe-4 text-end text-bold"><h6>Order Details</h6></div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-2 mb-2">
                                                            <div style={{ fontSize: "14px" }} className="mb-2 text-center">
                                                                {cartRecord.productCatigory}
                                                            </div>
                                                            <div>
                                                                <Link to={"/itemdetail/" + cartRecord.itemId}><img className="img-fluid w-40" src={imageBends + cartRecord.uploadFile} style={{ objectFit: "cover" }} alt="" />
                                                                </Link>
                                                            </div>
                                                        </div>
                                                        <div className="col-7">
                                                            <Link to={"/itemdetail/" + cartRecord.itemId}>
                                                                <div className="col-12">
                                                                    <h6>{cartRecord.itemName}</h6>
                                                                </div>

                                                                <div className="col-12 pt-5">
                                                                    Quantity: {cartRecord.quantity}
                                                                </div>
                                                                <div className="col-12">
                                                                    Unit Cost: ₦{numberWithCommas(cartRecord.unitCost)}
                                                                </div>
                                                            </Link>

                                                        </div>
                                                        <div className="col-3 mb-2">
                                                            <div className="text-center mt-4 ">
                                                                <h6>₦{numberWithCommas(cartRecord.totalCost)}</h6>
                                                            </div>
                                                            <button className="btn btn-danger col-12" id="delete" data-value={cartRecord.id} onClick={deleteItems}><i className="fa fa-trash"></i> Delete </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        })}
                                    </div>
                                </> :
                                <>

                                    <div className="col-lg-9 p-lg-3 text-center align-item-center" style={{ minHeight: "400px" }}>
                                        <h3 className="align-item-center">EMPTY! CART</h3>
                                        <i className="fa fa-shopping-cart p-3" style={{ fontSize: '15em' }}></i>
                                        <Link to='/product'> <h5 className="btn btn-primary align-item-center">Return To Shopping</h5></Link>
                                    </div>
                                </>
                            }
                        </>

                        <>
                            <div className="col-lg-3 p-lg-3 about-text p-3 bg-white wow fadeIn" data-wow-delay="0.5s">
                                <div className="row m-3 mt-0">
                                    <div className="col-12 m-3 pb-3 text-center border-bottom">
                                        <h6>Cart Total</h6>
                                    </div>
                                    <div className="col-6 mb-3 text-start border-bottom">
                                        <h6>Subtotal</h6>
                                    </div>
                                    <div className="col-6 mb-3 text-start border-bottom">
                                        ₦{numberWithCommas(totalcost)}
                                    </div>
                                    <div className="col-6 mb-3 text-start border-bottom">
                                    </div>
                                    <div className="col-6 mb-3 text-start border-bottom">
                                        ₦{numberWithCommas(5000)}
                                    </div>
                                    <div className="col-6 mb-3 text-start border-bottom">
                                        <h6>Total</h6>
                                    </div>
                                    <div className="col-6 mb-3 text-start border-bottom">
                                        ₦{numberWithCommas(totalcost + 5000)}
                                        {localStorage.setItem('totalcost', totalcost + 5000)}
                                    </div>
                                    <div className="col-12 mb-4 text-start">
                                        <Link to={"/checkout/" + browserToken} className="btn btn-primary py-2 px-5 col-12">
                                            Checkout
                                            <i className="fa fa-shopping-cart ms-1"></i>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </>
                    </div>
                </div>
            </div>
            {/* Dashboards  */}
        </>
    )
}

export default Cart
