import React, { useEffect, useState } from "react";
import axios from "axios";
import Loading from "../appComponents/Loading";
import swal from "sweetalert";
const TestimonialSlide = (props) => {

    const [allTestimonials, setAllTestimonials] = useState([]); //store defualt search records

    const [imageBends, setImageBends] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        (async () => await tableLoad11())();
    }, []);

    async function tableLoad11(teamCat) {
        let datas

        datas = "?pageLayout[eq]=Testimonial&activeStatus[eq]=Active";

        await axios.get("api/user/v1/post_page" + datas).then((res) => {
            // Gets the address of the backend server to be able to point the Images currectly
            if (res.data.status === 200) {
                //localStorage.setItem("bend", res.data.bend);

                setAllTestimonials(res.data.allData);
                console.log(res.data.bend);
            } else if (res.data.status === 401) {
                swal("Oop!", res.data.message, "warning");

            } else if (res.data.status === 500) {
                swal("Oop!", res.data.message, "warning");

            }
            else {
                swal("Warning", res.data.message, "warning");
                console.log(res.data.message);
                console.log(res.data.validation_error);

            }
        });


        axios.get("api/user/v1/general").then((res) => {// Gets Image server location
            if (res.data.status === 200) {
                //localStorage.setItem("bend", res.data.bend);
                setImageBends(res.data.bend);
                setLoading(false);
                console.log(res.data.bend);
            } else if (res.data.status === 401) {
                swal("Oop!", res.data.message, "warning");

            } else if (res.data.status === 500) {
                swal("Oop!", res.data.message, "warning");

            }
            else {
                swal("Warning", res.data.message, "warning");
                console.log(res.data.message);
                console.log(res.data.validation_error);

            }
        });

    }

    if (loading) {
        // Loading, Processing Payment, Please Dont Interrupt
        return <Loading message01={'Loading...'} />;
    }

    return (
        <>
            {allTestimonials.map((allTestimonial, index) => {
                let activity = '';
                if (index === 0) {
                    activity = 'active';
                }
                return (
                    <>

                        <div className={"carousel-item " + activity}>
                            <div className="img-box"><img src={imageBends + allTestimonial.uploadFile} alt="" /></div>
                            <p className="testimonial"><div dangerouslySetInnerHTML={{ __html: allTestimonial.message }} /></p>
                            <p className="overview">
                                <b>{allTestimonial.pageTitle}</b>
                                {allTestimonial.extra01 ? <><div className="details text-primary">{allTestimonial.extra01}</div></> : ''}
                            </p>
                            <div className="star-rating">
                                <ul className="list-inline">
                                    <li className="list-inline-item"><i className="fa fa-star"></i></li>
                                    <li className="list-inline-item"><i className="fa fa-star"></i></li>
                                    <li className="list-inline-item"><i className="fa fa-star"></i></li>
                                    <li className="list-inline-item"><i className="fa fa-star"></i></li>
                                    <li className="list-inline-item"><i className="fa fa-star-o"></i></li>
                                </ul>
                            </div>
                        </div>

                    </>)
            })}

        </>
    )
}

export default TestimonialSlide
