import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import Loading from "../appComponents/Loading";
import swal from "sweetalert";
const TeamDetail = () => {
    const { teamid } = useParams();
    const [teamidToken, setTeamidToken] = useState({ teamid }.teamid);
    const [loading, setLoading] = useState(true);

    const [teamDetailRecords, setTeamDetailRecords] = useState([]); //store defualt search records
    const [imageBends, setImageBends] = useState();

    useEffect(() => {
        (async () => await tableLoad())();
    }, []);

    async function tableLoad() {
        let datas;

        datas = "?id[eq]=" + teamidToken + "&activeStatus[eq]=Active";
        console.log(teamidToken, '$$rrt');
        await axios.get("api/user/v1/post_page" + datas).then((res) => {
            // Gets the address of the backend server to be able to point the Images currectly
            if (res.data.status === 200) {
                //localStorage.setItem("bend", res.data.bend);

                setTeamDetailRecords(res.data.allData);
                console.log(res.data.bend);
            } else if (res.data.status === 401) {
                swal("Oop!", res.data.message, "warning");

            } else if (res.data.status === 500) {
                swal("Oop!", res.data.message, "warning");

            }
            else {
                swal("Warning", res.data.message, "warning");
                console.log(res.data.message);
                console.log(res.data.validation_error);

            }
        });


        axios.get("api/user/v1/general").then((res) => {// Gets the address of the backend server to be able to point the Images currectly
            if (res.data.status === 200) {
                //localStorage.setItem("bend", res.data.bend);
                setImageBends(res.data.bend);
                setLoading(false);
                console.log(res.data.bend);
            } else if (res.data.status === 401) {
                swal("Oop!", res.data.message, "warning");

            } else if (res.data.status === 500) {
                swal("Oop!", res.data.message, "warning");

            }
            else {
                swal("Warning", res.data.message, "warning");
                console.log(res.data.message);
                console.log(res.data.validation_error);

            }
        });
    }

    if (loading) {
        // Loading, Processing Payment, Please Dont Interrupt
        return <Loading message01={'Loading...'} />;
    }
    return (
        <>
            {/* TeamDetail  */}
            <div className="container bg-light overflow-hidden my-5 px-lg-0">
                <div className="container about px-lg-0">

                    {teamDetailRecords.map((teamDetailRecord, index) => {

                        return <>
                            <div className="row g-0 mx-lg-0">
                                <div className="col-lg-6 p-lg-5" style={{ minHeight: "400px" }}>

                                    <div className="position-relative h-100">

                                        <img className="position-absolute img-fluid w-100" src={imageBends + teamDetailRecord.uploadFile} style={{ objectFit: "cover" }} alt="" />
                                    </div>
                                </div>
                                <div className="col-lg-6 about-text p-5 wow fadeIn" data-wow-delay="0.5s">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>
                                                    <h3 className="col-md-12">{teamDetailRecord.pageTitle}</h3>
                                                    <h5 className="col-md-12 float-end">({teamDetailRecord.extra01})</h5>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            <tr>
                                                <div dangerouslySetInnerHTML={{ __html: teamDetailRecord.message }} />
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div >
                        </>
                    })}

                </div>
            </div >
            {/* TeamDetail  */}
        </>
    )
}

export default TeamDetail
