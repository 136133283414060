import React from 'react'
import { Link } from "react-router-dom";
const Slider = () => {
    return (
        <>
            {/* Carousel Start */}
            <div className="container-fluid p-0 pb-5">
                <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
                    <div className="carousel-indicators">
                        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
                        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
                    </div>
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <img src="assets/img/1.png" className="d-block w-100" alt="" />
                            <div className="carousel-caption position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center" style={{ background: "rgba(53, 53, 53, .7)" }}>
                                <div className="container">
                                    <div className="row justify-content-center">
                                        <div className="col-12 col-lg-8 text-center">
                                            <h3 className="text-white text-uppercase mb-3 animated slideInDown">Welcome To Savvy Premier</h3>
                                            <h5 className="display-5 text-white animated slideInDown mb-4">For Strong & Durable Products</h5>
                                            <p className="fs-6 fw-medium text-white mb-4 pb-2">Vero elitr justo clita lorem. Ipsum dolor at sed stet sit diam no.</p>
                                            <Link to="/aboutus" className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft">Read More</Link>
                                            <Link to="/quote" className="btn btn-light py-md-3 px-md-5 animated slideInRight">Free Quote</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <img src="assets/img/2.png" className="d-block w-100" alt="" />
                            <div className="carousel-caption position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center" style={{ background: "rgba(53, 53, 53, .7)" }}>
                                <div className="container">
                                    <div className="row justify-content-center">
                                        <div className="col-12 col-lg-8 text-center">
                                            <h3 className="text-white text-uppercase mb-3 animated slideInDown">Welcome To Savvy Premier</h3>
                                            <h5 className="display-5 text-white animated slideInDown mb-4">For Strong & Durable Products</h5>
                                            <p className="fs-6 fw-medium text-white mb-4 pb-2">Vero elitr justo clita lorem. Ipsum dolor at sed stet sit diam no.</p>
                                            <Link to="/aboutus" className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft">Read More</Link>
                                            <Link to="/quote" className="btn btn-light py-md-3 px-md-5 animated slideInRight">Free Quote</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <img src="assets/img/3.png" className="d-block w-100" alt="" />
                            <div className="carousel-caption position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center" style={{ background: "rgba(53, 53, 53, .7)" }}>
                                <div className="container">
                                    <div className="row justify-content-center">
                                        <div className="col-12 col-lg-8 text-center">
                                            <h3 className="text-white text-uppercase mb-3 animated slideInDown">Welcome To Savvy Premier</h3>
                                            <h5 className="display-5 text-white animated slideInDown mb-4">For Strong & Durable Products</h5>
                                            <p className="fs-6 fw-medium text-white mb-4 pb-2">Vero elitr justo clita lorem. Ipsum dolor at sed stet sit diam no.</p>
                                            <Link to="/aboutus" className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft">Read More</Link>
                                            <Link to="/quote" className="btn btn-light py-md-3 px-md-5 animated slideInRight">Free Quote</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button> */}
                </div>
            </div>
        </>
    )
}

export default Slider
