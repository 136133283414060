import React from "react";
import ItemDetail from "./childComponents/pageLayouts/ItemDetail";

const ItemDetails = (props) => {

    
    return (
        <>
            <ItemDetail catigoryChild={props.catigoryChild}/>

        </>
    )
}

export default ItemDetails
